import {sendGetRequest, sendPutRequest} from 'api/utils';
import {api_url} from 'api/utils/consts';
import {ErrorInterface} from 'api/utils/types';
import {AllDevicesResponse, Device, UpdateDeviceResponse} from './types';

export default () => ({
  getDevices: async (): Promise<Device[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem('token') || '';
      const {data}: AllDevicesResponse = await sendGetRequest(
        api_url + '/api/v1.0/tm/device',
        token,
      );
      return data;
    } catch (error) {
      return {
        message: 'Eroare: ' + (error as Error).message,
        errorCode: '400',
      };
    }
  },

  updateDevice: async (
    id: number,
    bodyObject: object,
  ): Promise<Device | ErrorInterface> => {
    try {
      const token = localStorage.getItem('token') || '';
      const {data}: UpdateDeviceResponse = await sendPutRequest(
        api_url + '/api/v1.0/tm/device/userUpdate/' + id,
        bodyObject,
        token,
      );
      return data;
    } catch (error) {
      return {
        message: 'Eroare: ' + (error as Error).message,
        errorCode: '400',
      };
    }
  },
});
