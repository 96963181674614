import { yupResolver } from "@hookform/resolvers/yup";
import { apiFactory } from "api";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as yup from "yup";

const validationSchemaEdit = yup.object().shape({
  userName: yup.string().required("Camp obligatoriu"),
  firstName: yup.string().required("Camp obligatoriu"),
  roleId: yup.number().typeError("Nu a fost selectat un rol"),
  currentProjectId: yup.number().typeError("Nu a fost selectat un proiect"),
  lastName: yup.string().required("Camp obligatoriu"),
  teamId: yup.number().typeError("Nu a fost selectată o echipă"),
});

const validationSchemaAdd = yup.object().shape({
  userName: yup.string().required("Camp obligatoriu"),
  firstName: yup.string().required("Camp obligatoriu"),
  lastName: yup.string().required("Camp obligatoriu"),
  password: yup.string().required("Camp obligatoriu").min(6),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), undefined], "Parolele trebuie sa fie identice")
    .required("Este necesara parola pentru confimare")
    .min(6),
  roleId: yup.number().typeError("Nu a fost selectat un rol"),
  currentProjectId: yup.number().typeError("Nu a fost selectat un proiect"),
  teamId: yup.number().typeError("Nu a fost selectată o echipă"),
});

const objIncr = { count: 0 };

const UsersModal = ({
  modalOpen,
  toggleModal,
  handleRefresh,
  editDefaultValues,
  addMode,
  projectNames,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    // setValues,
    errors,
    formState,
    setValue,
  } = useForm({
    resolver: yupResolver(addMode ? validationSchemaAdd : validationSchemaEdit),
    reValidateMode: "onChange",
    defaultValues: useMemo(() => editDefaultValues, [editDefaultValues]),
  });
  const [proj, setProj] = useState();
  const [test, setTest] = useState();
  const [teams, setTeams] = useState(null);
  const [roles, setRoles] = useState(null);

  const onSubmit = async (data) => {
    const updateUser = async () => {
      await apiFactory().data.users().updateUser(data, editDefaultValues.id);
      handleRefresh();
    };
    const createUser = async () => {
      await apiFactory().data.users().createUser({
        userName: data.userName,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        currentProjectId: data.currentProjectId,
        roleId: data.roleId,
        teamId: data.teamId,
      });
      handleRefresh();
    };

    if (_.isEmpty(editDefaultValues)) {
      createUser();
    } else {
      updateUser();
    }
    toggleModal();
  };

  const fetchRoles = async () => {
    const newRoles = await apiFactory().data.users().getRoles();
    setRoles(newRoles);
  };

  const fetchTeams = async () => {
    const newTeams = await apiFactory().data.users().getTeams();
    setTeams(newTeams);
  };

  useEffect(() => {
    fetchRoles();
    fetchTeams();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!_.isEmpty(editDefaultValues)) {
        for (var key of Object.keys(editDefaultValues)) {
          setValue(key, editDefaultValues[key]);
        }
      }
    });
  }, [editDefaultValues, modalOpen]);

  const getProjects = async () => {
    const newProjects = await apiFactory().data.projects().getProjects();
    let proj = [];
    newProjects.map((project) => {
      proj.push({ id: project.id, name: project.name });
    });
    setTest(proj);
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <Modal
      isOpen={modalOpen}
      toggle={toggleModal}
      wrapClassName="modal-right"
      backdrop="static"
    >
      <Card className="bg-secondary shadow border-0">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggleModal} style={{ fontSize: "1rem" }}>
            {editDefaultValues ? "Editeaza Utilizator" : "Adauga Utilizator"}
          </ModalHeader>
          <ModalBody>
            <Label>Username</Label>
            <Input
              innerRef={register}
              type="text"
              name="userName"
              maxLength={255}
              placeholder=""
              className="form-control-alternative"
            />
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.Username?.message}
            </p>

            <Label>Prenume</Label>
            <Input
              innerRef={register}
              type="text"
              name="firstName"
              maxLength={255}
              placeholder=""
              className="form-control-alternative"
            />
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.firstName?.message}
            </p>

            <Label>Nume</Label>
            <Input
              innerRef={register}
              type="text"
              name="lastName"
              maxLength={255}
              placeholder=""
              className="form-control-alternative"
            />
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.lastName?.message}
            </p>

            <Label>Rol</Label>
            <Input
              innerRef={register}
              type="select"
              name="roleId"
              className="form-control-alternative"
            >
              {roles !== null && (
                <>
                  <option></option>
                  {roles.map((role) => {
                    return (
                      <option value={role.id} key={"role_" + role.id}>
                        {role.name}
                      </option>
                    );
                  })}
                </>
              )}
            </Input>
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.roleId?.message}
            </p>

            <Label>Proiect</Label>
            <Input
              innerRef={register}
              type="select"
              name="currentProjectId"
              className="form-control-alternative"
            >
              {test && (
                <>
                  <option></option>
                  {test.map((element) => {
                    return (
                      <option value={element.id} key={element.id}>
                        {element.name}
                      </option>
                    );
                  })}
                </>
              )}
            </Input>
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.currentProjectId?.message}
            </p>

            <Label>Echipă</Label>
            <Input
              innerRef={register}
              type="select"
              name="teamId"
              className="form-control-alternative"
            >
              {teams !== null && (
                <>
                  <option></option>
                  {teams.map((team) => {
                    return (
                      <option value={team.id} key={"team_" + team.id}>
                        {team.name}
                      </option>
                    );
                  })}
                </>
              )}
            </Input>
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.teamId?.message}
            </p>

            {_.isEmpty(editDefaultValues) && (
              <>
                <Label>Parolă</Label>
                <Input
                  innerRef={register}
                  type="password"
                  name="password"
                  maxLength={255}
                  placeholder=""
                  className="form-control-alternative"
                />
                <p className="text-danger font-weight-normal text-xs mt-2">
                  {errors.password?.message}
                </p>

                <Label>Confirmare Parolă</Label>
                <Input
                  innerRef={register}
                  type="password"
                  name="confirmPassword"
                  maxLength={255}
                  placeholder=""
                  className="form-control-alternative"
                />
                <p className="text-danger font-weight-normal text-xs mt-2">
                  {errors.confirmPassword?.message}
                </p>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              Renunță
            </Button>
            <Button color="primary" type="submit">
              Salvează
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Card>
    </Modal>
  );
};

export default UsersModal;
