import React, { useEffect, useState } from "react";
import { Button, Alert } from "reactstrap";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./wrapper.css";

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.href = window.location.href;
  };

  return (
    showReload && (
      <Alert className="alert-new-version d-flex justify-content-between align-items-center">
        <span className="alert-inner--text">O nouă versiune este disponibilă!</span>
        <Button size="sm" onClick={reloadPage}>
          Reload
        </Button>
      </Alert>
    )
  );
};

export default ServiceWorkerWrapper;
