import { PAResponse, Users } from "api/users";
import { sendGetRequest, sendPostRequest, sendPutRequest } from "api/utils";
import { ErrorInterface } from "api/utils/types";
import { api_url } from "../utils/consts";
import { Task, TaskResponse } from "./types";

export default () => ({
  getTasks: async (idProject, idUser): Promise<Task[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || "";
      let suffix = ``;
      if (idProject !== 0) {
        if (suffix === ``) {
          suffix += `?projectId=${idProject}`;
        } else {
          suffix += `&projectId=${idProject}`;
        }
      }
      if (idUser !== undefined && idUser !== 0) {
        if (suffix === ``) {
          suffix += `?userId=${idUser}`;
        } else {
          suffix += `&userId=${idUser}`;
        }
      }
      const { data }: TaskResponse = await sendGetRequest(
        api_url + "/api/v1.0/tm/task/" + suffix,
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  putUpdateTaskStatus: async (id: number, stateId: number) => {
    try {
      const token = localStorage.getItem("token") || "";
      await sendPutRequest(
        api_url + "/api/v1.0/tm/task/" + id,
        { stateId: stateId },
        token
      );
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  getTaskStates: async () => {
    try {
      const token = localStorage.getItem("token") || "";
      const { data } = await sendGetRequest(
        api_url + "/api/v1.0/tm/state",
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare",
        errorCode: "400",
      };
    }
  },
});
