import { sendGetRequest, sendPutRequest, sendPostRequest } from "api/utils";
import { ErrorInterface } from "api/utils/types";
import { api_url } from "../utils/consts";
import { Client, ClientRespone } from "./types";

export default () => ({
  getClients: async (): Promise<Client[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || "";
      const { data }: ClientRespone = await sendGetRequest(
        api_url + "/api/v1.0/tm/client",
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  updateClient: async (
    client: Partial<Client>,
    id: number
  ): Promise<Client[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || "";
      const { data }: ClientRespone = await sendPutRequest(
        api_url + "/api/v1.0/tm/client/" + id,
        client,
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  createClient: async (
    client: Client
  ): Promise<Client[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || "";
      const { data }: ClientRespone = await sendPostRequest(
        api_url + "/api/v1.0/tm/client",
        client,
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },

});
