import React, { useState } from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { handleLogin } from "utils";
import { useEffect } from "react";

const validationSchema = yup.object().shape({
  userName: yup.string().required(),
  password: yup.string().required().min(6),
});

const Login = () => {
  const [generalErrorMessage, setGeneralErrorMessage] = useState("");
  const { register, handleSubmit, watch, errors, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const history = useHistory();

  const onSubmit = async (data) => {
    const token = await handleLogin(data.userName, data.password);
    if (token.errorCode) {
      setGeneralErrorMessage("User or password incorect, please try again!");
    } else {
      token && history.push(`/admin/index`);
    }
  };
  const usernameWatch = watch("userName");

  useEffect(() => {
    if (generalErrorMessage.length > 0) {
      setGeneralErrorMessage("");
    }
  }, [usernameWatch]);

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Autentificare</small>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="userName"
                    innerRef={register}
                    placeholder="Username"
                    type="text"
                    autoComplete="new-email"
                  />
                </InputGroup>
                <p className="text-danger font-weight-normal text-xs mt-2">
                  {errors.userName?.message}
                </p>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="password"
                    innerRef={register}
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                  />
                </InputGroup>
                <p className="text-danger font-weight-normal text-xs mt-2">
                  {errors.password?.message}
                </p>
              </FormGroup>
              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  name="rememberMe"
                  ref={register}
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div> */}
              <div className="text-center">
                <p className="text-danger font-weight-normal text-sm mt-4">
                  {generalErrorMessage}
                </p>
              </div>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Intră în aplicație
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
