import { sendGetRequest, sendPutRequest, sendPostRequest } from 'api/utils';
import { ErrorInterface } from 'api/utils/types';
import { api_url } from '../utils/consts';
import {
  Users,
  PAResponse,
  Task,
  TaskResponse,
  Roles,
  RolesResponse,
  Teams,
  TeamsResponse,
  NewTeam,
} from './types';

export default () => ({
  getUsers: async (
    idProject,
    idTeam,
    idRole
  ): Promise<Users[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem('token') || '';
      let suffix = ``;
      if (idProject !== undefined && idProject !== 0) {
        if (suffix === ``) {
          suffix += `?currentProjectId=${idProject}`;
        } else {
          suffix += `&currentProjectId=${idProject}`;
        }
      }
      if (idTeam !== undefined && idTeam !== 0) {
        if (suffix === ``) {
          suffix += `?teamId=${idTeam}`;
        } else {
          suffix += `&teamId=${idTeam}`;
        }
      }
      if (idRole !== undefined && idRole !== 0) {
        if (suffix === ``) {
          suffix += `?roleId=${idRole}`;
        } else {
          suffix += `&roleId=${idRole}`;
        }
      }
      const { data }: PAResponse = await sendGetRequest(
        api_url + '/api/v1.0/tm/user/' + suffix,
        token
      );
      return data;
    } catch (error) {
      return {
        message: 'Eroare.',
        errorCode: '400',
      };
    }
  },
  getTeams: async (): Promise<Teams[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem('token') || '';
      const { data }: TeamsResponse = await sendGetRequest(
        api_url + '/api/v1.0/tm/user/team',
        token
      );
      return data;
    } catch (error) {
      return {
        message: 'Eroare.',
        errorCode: '400',
      };
    }
  },
  updateUser: async (
    user: Partial<Users>,
    id: number
  ): Promise<Users[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem('token') || '';
      const { data }: PAResponse = await sendPutRequest(
        api_url + '/api/v1.0/tm/user/' + id,
        user,
        token
      );
      return data;
    } catch (error) {
      return {
        message: 'Eroare.',
        errorCode: '400',
      };
    }
  },
  updateTeam: async (
    team: Partial<Teams>,
    id: number
  ): Promise<Teams[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem('token') || '';
      const { data }: TeamsResponse = await sendPutRequest(
        api_url + '/api/v1.0/tm/user/team/' + id,
        team,
        token
      );
      return data;
    } catch (error) {
      return {
        message: 'Eroare.',
        errorCode: '400',
      };
    }
  },
  createTeam: async (team: NewTeam) => {
    try {
      const token = localStorage.getItem('token') || '';
      await sendPostRequest(api_url + '/api/v1.0/tm/user/team', team, token);
    } catch (error) {
      return {
        message: 'Eroare.',
        errorCode: '400',
      };
    }
  },
  createUser: async (user: Users): Promise<Users[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem('token') || '';
      const { data }: PAResponse = await sendPostRequest(
        api_url + '/api/v1.0/tm/user/signUp',
        user,
        token
      );
      return data;
    } catch (error) {
      return {
        message: 'Eroare.',
        errorCode: '400',
      };
    }
  },
  getUserTasks: async (id: number): Promise<Task[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem('token') || '';
      const { data }: TaskResponse = await sendGetRequest(
        api_url + '/api/v1.0/tm/task/?userId=' + id,
        token
      );
      return data;
    } catch (error) {
      return [];
    }
  },
  getRoles: async (): Promise<Roles[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem('token') || '';
      const { data }: RolesResponse = await sendGetRequest(
        api_url + '/api/v1.0/tm/role',
        token
      );
      return data;
    } catch (error) {
      return {
        message: 'Eroare.',
        errorCode: '400',
      };
    }
  },
  getUsersTasks: async (): Promise<Task[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem('token') || '';
      const { data }: TaskResponse = await sendGetRequest(
        api_url + '/api/v1.0/tm/user/tasks',
        token
      );
      return data;
    } catch (error) {
      return [];
    }
  },
});
