import {sendGetRequest, sendPostRequest, sendPutRequest} from 'api/utils';
import {ErrorInterface} from 'api/utils/types';
import moment from 'moment';
import {api_url} from '../utils/consts';
import {
  Clocking,
  ClockingStatistics,
  InvoiceUser,
  PAResponse,
  ProjectStats,
  PSResponse,
  TSResponse,
  UsersStats,
  USResponse,
} from './types';

export default () => ({
  getClockings: async (
    idProject: number,
    idUser: number,
    idTeam: number,
    invoiced: number,
    startDate: Date,
    endDate: Date,
  ): Promise<Clocking[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem('token') || '';
      let suffix = ``;
      if (idProject !== undefined && idProject !== 0) {
        if (suffix === ``) {
          suffix += `?idProject=${idProject}`;
        } else {
          suffix += `&idProject=${idProject}`;
        }
      }
      if (idUser !== undefined && idUser !== 0) {
        if (suffix === ``) {
          suffix += `?idUser=${idUser}`;
        } else {
          suffix += `&idUser=${idUser}`;
        }
      }
      if (idTeam !== undefined && idTeam !== 0) {
        if (suffix === ``) {
          suffix += `?teamId=${idTeam}`;
        } else {
          suffix += `&teamId=${idTeam}`;
        }
      }
      if (invoiced !== undefined && invoiced !== -1) {
        if (suffix === ``) {
          suffix += `?invoiced=${invoiced}`;
        } else {
          suffix += `&invoiced=${invoiced}`;
        }
      }
      if (startDate !== null) {
        if (suffix === ``) {
          suffix += `?startDate=${startDate}`;
        } else {
          suffix += `&startDate=${startDate}`;
        }
      }
      if (endDate !== null) {
        if (suffix === ``) {
          suffix += `?endDate=${endDate}`;
        } else {
          suffix += `&endDate=${endDate}`;
        }
      }
      const {data}: PAResponse = await sendGetRequest(
        api_url + '/api/v1.0/tm/clocking' + suffix,
        token,
      );
      return data;
    } catch (error) {
      return {
        message: 'Eroare.',
        errorCode: '400',
      };
    }
  },
  createClocking: async (
    clockingInfo: { comment: string, taskId: number | null },
  ): Promise<Clocking[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem('token') || '';
      const {data}: PAResponse = await sendPostRequest(
        api_url +
          `/api/v1.0/tm/clocking/?timestamp=${moment().format(
            'YYYY-MM-DD HH:mm:ss',
          )}`,
        clockingInfo, // Pass the taskId along with the clocking info
        token,
      );
      return data;
    } catch (error) {
      return {
        message: 'Eroare.',
        errorCode: '400',
      };
    }
  },
  latestClocking: async (): Promise<Clocking[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem('token') || '';
      const {data}: PAResponse = await sendGetRequest(
        api_url + '/api/v1.0/tm/clocking/latest',
        token,
      );
      return data;
    } catch (error) {
      return {
        message: 'Eroare.',
        errorCode: '400',
      };
    }
  },
  updateInvoiceClocking: async (
    id: number,
  ): Promise<Clocking[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem('token') || '';
      const {data}: PAResponse = await sendPutRequest(
        api_url + '/api/v1.0/tm/clocking/' + id,
        {invoiced: 0},
        token,
      );
      return data;
    } catch (error) {
      return {
        message: 'Eroare.',
        errorCode: '400',
      };
    }
  },
  getPorjectStatisticsClocking: async (
    idProject: number,
  ): Promise<ProjectStats[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem('token') || '';
      const {data}: PSResponse = await sendGetRequest(
        api_url +
          '/api/v1.0/tm/clocking/statistics/project?idProject=' +
          idProject +
          '&invoiced=0',
        token,
      );
      return data;
    } catch (error) {
      return {
        message: 'Eroare.',
        errorCode: '400',
      };
    }
  },

  getUsersStatisticsClockingTVBoard: async (): Promise<
    ClockingStatistics[] | ErrorInterface
  > => {
    try {
      const token = localStorage.getItem('token') || '';
      const {data}: TSResponse = await sendGetRequest(
        api_url + '/api/v1.0/tm/clocking/statistics/tvBoard',
        token,
      );
      return data;
    } catch (error) {
      return {
        message: 'Eroare:' + error,
        errorCode: '400',
      };
    }
  },

  getUsersStatisticsClocking: async (
    idProject: number,
    idTeam: number,
  ): Promise<UsersStats[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem('token') || '';
      const {data}: USResponse = await sendGetRequest(
        api_url +
          '/api/v1.0/tm/clocking/statistics/user?idProject=' +
          idProject +
          '&invoiced=0' +
          `${idTeam ? '&teamId=' + idTeam : ''}`,
        token,
      );
      return data;
    } catch (error) {
      return {
        message: 'Eroare.',
        errorCode: '400',
      };
    }
  },
  putInvoiceByUser: async (data: InvoiceUser) => {
    try {
      const token = localStorage.getItem('token') || '';
      await sendPutRequest(api_url + '/api/v1.0/tm/clocking/bulk', data, token);
      // return data;
    } catch (error) {
      return {
        message: 'Eroare.',
        errorCode: '400',
      };
    }
  },

  // putInvoiceByProject: async (data: InvoiceProject, id) => {
  //   try {
  //     const token = localStorage.getItem("token") || "";
  //     await sendPutRequest(
  //       api_url + "/api/v1.0/tm/clocking/" + id,
  //       data,
  //       token
  //     );
  //     // return data;
  //   } catch (error) {
  //     return {
  //       message: error.message,
  //       errorCode: error.code,
  //     };
  //   }
  // },
});
