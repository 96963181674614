// src/utils/authHelpers.js
import { apiFactory } from 'api';
import routes from 'routes.js';

export const handleLogin = async (userName, password) => {
    const token = await apiFactory().data.auth().login({
        userName: userName,
        password: password,
    });
    if (token.errorCode) {
        return token;
    }
    localStorage.setItem('token', token);
    if (token) {
        return token;
    }
};

export const checkUserPermissions = async (currentPath, history) => {
    const userInfo = await apiFactory().data.auth().getMyUser();

    if (userInfo && userInfo.length > 0) {
        const roleId = userInfo[0].roleId;
        const userId = userInfo[0].id

        const route = routes.find(r => {
            const routePath = r.layout + r.path;
            const routeSegments = routePath.split('/').filter(Boolean);
            const currentSegments = currentPath.split('/').filter(Boolean);

            if (routeSegments.length === currentSegments.length) {
                const isMatch = routeSegments.every((seg, index) => {
                    const segmentMatch = seg.startsWith(':') || seg === currentSegments[index];
                    return segmentMatch;
                });

                if (isMatch) {
                    return {'userId': userId, 'roleId': roleId};
                }
            }

            return false;
        });

        if (route) {
            const notPermitedRoleIds = route.notPermitedRoleIds || [];

            if (notPermitedRoleIds.includes(roleId)) {
                history.push('/unauthorized');
                return false;
            }
            return {'userId': userId, 'roleId': roleId};
        } else {
            history.push('/unauthorized');
            return false;
        }
    } else {
        history.push('/auth/login');
        return false;
    }


};
