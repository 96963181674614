import { sendGetRequest, sendPostRequest } from 'api/utils';
import { ErrorInterface } from 'api/utils/types';
import { api_url } from '../utils/consts';
import {
  LightPoint,
  PAResponse,
} from './types';


export default () => ({

  getLightPoints: async (): Promise<LightPoint[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || '';
      const { data }: PAResponse = await sendGetRequest(
        api_url + '/api/v1.0/lm/puncteLuminoase',
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  insertLightPoint: async (lightPoint: LightPoint): Promise<LightPoint[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || '';
      const { data }: PAResponse = await sendPostRequest(
        api_url + '/api/v1.0/lm/puncteLuminoase',
        lightPoint,
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  updateLightPoint: async (lightPoint: Partial<LightPoint>, id: number): Promise<LightPoint[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || '';
      const { data }: PAResponse = await sendPostRequest(
        api_url + '/api/v1.0/lm/puncteLuminoase/' + id,
        lightPoint,
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },

});
