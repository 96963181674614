import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

import { Container, Row, Col, NavbarBrand } from "reactstrap";
import AuthFooter from "components/Footers/AuthFooter.js";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";

import routes from "routes.js";
const logo = {
  innerLink: "/admin/index",
  imgSrc: require("../assets/img/brand/TM_Prim_Logo.svg").default,
  imgAlt: "...",
};

let navbarBrandProps;
if (logo && logo.innerLink) {
  navbarBrandProps = {
    to: logo.innerLink,
    tag: Link,
  };
} else if (logo && logo.outterLink) {
  navbarBrandProps = {
    href: logo.outterLink,
    target: "_blank",
  };
}

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className='main-content' ref={mainContent}>
        {/* <AuthNavbar /> */}
        <div className='main-content-background'></div>
        <div className='header  pt-4 pt-lg-4 pb-2 pb-lg-2'>
          <Container fluid>
            <div className='header-body text-start mb-7'>
              <Row className='justify-content-center'>
                <Col lg='2' md='2' className='justify-content-center d-flex'>
                  <NavbarBrand className='mt-7' {...navbarBrandProps}>
                    <img
                      alt={logo.imgAlt}
                      className='navbar-brand-img'
                      src={logo.imgSrc}
                    />
                  </NavbarBrand>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className='mt-0 pb-5'>
          <Row className='justify-content-center'>
            <Switch>
              {getRoutes(routes)}
              <Redirect from='*' to='/auth/login' />
            </Switch>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
};

export default Auth;
