import classnames from "classnames";
import DatatablePagination from "components/DatatablePagination";
import React, { useState } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Table,
  Input,
} from "reactstrap";
// import AddressModal from "components/account/editAddressModal";
import UsersModal from "components/Modals/UsersModal.js";

import { apiFactory } from "api";

function TableDefined({
  columns,
  data,
  divided = false,
  defaultPageSize = 10,
  setModalBasic,
  setForDelete,
  setModalLarge,
  setForModal,
  handleRefresh,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: defaultPageSize },
    },
    useSortBy,
    usePagination
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [editValues, setEditValues] = useState({});

  const truncString = (string) => {
    if (string && string.length >= 27) {
      return string.slice(0, 27) + "...";
    }
    return string || "";
  };

  const openEditModal = (row) => {
    const clickedData = data.find((data) => data.userName === row.userName);
    setModalOpen(!modalOpen);
    setEditValues(clickedData);
  };

  return (
    <>
      <UsersModal
        modalOpen={modalOpen}
        toggleModal={() => {
          setModalOpen(!modalOpen);
        }}
        handleRefresh={handleRefresh}
        editDefaultValues={editValues}
      />
      <Table
        {...getTableProps()}
        className={`r-table table ${classnames({
          "table-divided": divided,
        })} h-100`}
        responsive
      >
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={`th_${columnIndex}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sorted-desc"
                        : "sorted-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                  <span />
                </th>
              ))}
              <th scope="col" />
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <td
                      key={`td_${cellIndex}`}
                      {...cell.getCellProps({
                        className: cell.column.cellClass,
                      })}
                      style={
                        cell.column.cellClass === "list-item-heading w-10"
                          ? { verticalAlign: "middle", minWidth: "50px" }
                          : { verticalAlign: "middle", minWidth: "25px" }
                      }
                    >
                      {cell.column.cellClass === "list-item-heading w-10" ? (
                        <div
                          className="fake-a-tag"
                          style={{ color: "#39a36f" }}
                          onClick={() => {
                            setModalLarge(true);
                            setForModal(cell.row.original);
                          }}
                        >
                          {truncString(cell.value, 30)}
                        </div>
                      ) : (
                        truncString(cell.value, 30)
                      )}
                    </td>
                  );
                })}
                <td key={`td_5`} style={{ minWidth: "120px" }}>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-outline-primary mr-1 btn-sm"
                      onClick={() => {
                        openEditModal(row.values, row.original.id);
                      }}
                    >
                      <i className="ni ni-settings pr-2" />
                      Editează
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <DatatablePagination
        page={pageIndex}
        pages={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        pageSizeOptions={[5, 10, 20, 30, 40, 50]}
        showPageSizeOptions={true}
        showPageJump={false}
        defaultPageSize={pageSize}
        onPageChange={(p) => gotoPage(p)}
        onPageSizeChange={(s) => setPageSize(s)}
        paginationMaxSize={pageCount}
      />
    </>
  );
}

const UsersTable = ({ users, handleRefresh }) => {
  const [modalBasic, setModalBasic] = useState(false);
  const [forDelete, setForDelete] = useState();
  const [modalLarge, setModalLarge] = useState(false);
  const [forModal, setForModal] = useState();

  const cols = React.useMemo(() => [
    // {
    //   Header: "Id",
    //   accessor: "id",
    //   cellClass: "list-item-heading w-10",
    //   Cell: (props) => <>{props.value}</>,
    // },
    {
      Header: "Username",
      accessor: "userName",
      cellClass: "text-muted w-20",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Prenume",
      accessor: "firstName",
      cellClass: "text-muted  w-20",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Nume",
      accessor: "lastName",
      cellClass: "text-muted  w-20",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Proiect Curent",
      accessor: "currentProjectName",
      cellClass: "text-muted  w-15",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Echipa",
      accessor: "team",
      cellClass: "text-muted  w-15",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Rol",
      accessor: "roleName",
      cellClass: "text-muted  w-15",
      Cell: (props) => <>{props.value}</>,
    },
  ]);

  // const deleteAddress = async () => {
  //   await apiFactory().data.account().updateAddresses(forDelete, {
  //     isDeleted: 1,
  //   });
  //   handleRefresh();
  // };

  return (
    <>
      {/* <Table className="align-items-center table-flush h-100" responsive> */}
      <TableDefined
        columns={cols}
        data={users}
        setModalBasic={setModalBasic}
        setForDelete={setForDelete}
        setModalLarge={setModalLarge}
        setForModal={setForModal}
        handleRefresh={handleRefresh}
      />
      {/* </Table> */}
      <Modal isOpen={modalLarge} toggle={() => setModalLarge(!modalLarge)}>
        <ModalHeader toggle={() => setModalLarge(!modalLarge)}>
          Adresă
        </ModalHeader>
        <ModalBody>
          {forModal && (
            <>
              <p className="text-muted text-small mb-2">Adresă</p>
              <p>{forModal.name}</p>
              <p className="text-muted text-small mb-2">Detalii ridicare</p>
              <p>{forModal.description}</p>
              <p className="text-muted text-small mb-2">Județ</p>
              <p>{forModal.county}</p>
              <p className="text-muted text-small mb-2">Oraș</p>
              <p>{forModal.city}</p>
            </>
          )}
        </ModalBody>
      </Modal>

      <Modal isOpen={modalBasic} toggle={() => setModalBasic(!modalBasic)}>
        <ModalHeader
          style={{ borderBottom: "0px solid black" }}
          className="pb-0 text-lg"
        >
          <div className="text-lg pt-4 pb-2 m-4">
            Dorești să ștergi acestă adresă?
          </div>
        </ModalHeader>
        <ModalFooter style={{ borderTop: "0px solid black" }}>
          <Button
            color="btn btn-secondary "
            onClick={() => setModalBasic(false)}
          >
            Renunță
          </Button>
          <Button
            color="danger"
            onClick={() => {
              // deleteAddress();
              setModalBasic(false);
            }}
          >
            Șterge
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UsersTable;
