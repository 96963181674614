import classnames from 'classnames';
import DatatablePagination from 'components/DatatablePagination';
import DeviceModal from 'components/Modals/DeviceModal.js';
import React, {useState} from 'react';
import {usePagination, useSortBy, useTable} from 'react-table';
import {Table} from 'reactstrap';

function TableDefined({
  columns,
  data,
  divided = false,
  defaultPageSize = 10,
  setModalBasic,
  setForDelete,
  setModalLarge,
  setForModal,
  handleRefresh,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0, pageSize: defaultPageSize},
    },
    useSortBy,
    usePagination,
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [editValues, setEditValues] = useState({});

  const truncString = (string) => {
    if (string && string.length >= 27) {
      return string.slice(0, 27) + '...';
    }
    return string || '';
  };

  const openViewModal = (newData, id, requestReset) => {
    setModalOpen(!modalOpen);
    setEditValues({...newData, id: id, requestReset: requestReset});
  };

  return (
    <>
      <DeviceModal
        modalOpen={modalOpen}
        toggleModal={() => {
          setModalOpen(!modalOpen);
        }}
        handleRefresh={handleRefresh}
        editDefaultValues={editValues}
        hasErrors={editValues.hasErrors}
      />
      <table
        {...getTableProps()}
        className={`r-table table ${classnames({'table-divided': divided})}`}
      >
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={`th_${columnIndex}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'sorted-desc'
                        : 'sorted-asc'
                      : ''
                  }
                >
                  {column.render('Header')}
                  <span />
                </th>
              ))}
              <th scope="col" />
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => {
                  openViewModal(
                    row.values,
                    row.original.id,
                    row.original.requestReset,
                  );
                }}
                style={{cursor: 'pointer'}}
                className="hover-row"
              >
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <td
                      key={`td_${cellIndex}`}
                      {...cell.getCellProps({
                        className: cell.column.cellClass,
                      })}
                      style={
                        cell.value === 'Yes'
                          ? {
                              color: 'white',
                              textAlign: 'center',
                              backgroundColor: '#f5365c',
                            }
                          : cell.value === 'No'
                          ? {
                              color: 'white',
                              textAlign: 'center',
                              backgroundColor: '#2dce89',
                            }
                          : {
                              color: '#8898aa',
                            }
                      }
                    >
                      {cell.column.cellClass === 'list-item-heading w-10' ? (
                        <div
                          className="fake-a-tag"
                          onClick={() => {
                            setModalLarge(true);
                            setForModal(cell.row.original);
                          }}
                        >
                          {truncString(cell.value, 30)}
                        </div>
                      ) : (
                        truncString(cell.value, 30)
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <DatatablePagination
        page={pageIndex}
        pages={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        pageSizeOptions={[5, 10, 20, 30, 40, 50]}
        showPageSizeOptions={true}
        showPageJump={false}
        defaultPageSize={pageSize}
        onPageChange={(p) => gotoPage(p)}
        onPageSizeChange={(s) => setPageSize(s)}
        paginationMaxSize={pageCount}
      />
    </>
  );
}

const DevicesTable = ({devices, handleRefresh}) => {
  const [modalBasic, setModalBasic] = useState(false);
  const [forDelete, setForDelete] = useState();
  const [modalLarge, setModalLarge] = useState(false);
  const [forModal, setForModal] = useState();

  const cols = React.useMemo(
    () => [
      {
        Header: 'Nume Device',
        accessor: 'fullName',
        cellClass: ' w-25',
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: 'Serial Number',
        accessor: 'serialNumber',
        cellClass: ' w-25',
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: 'Local Ip',
        accessor: 'localIp',
        cellClass: ' w-25',
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: 'Public Ip',
        accessor: 'publicIp',
        cellClass: ' w-25',
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        cellClass: ' w-25',
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: 'Errors',
        accessor: 'hasErrors',
        cellClass: ' w-25',
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: 'Shield HW Ver.',
        accessor: 'shieldHw',
        cellClass: ' w-25',
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: 'Shield SW Ver.',
        accessor: 'shieldSw',
        cellClass: ' w-25',
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: 'Mixlight Ver.',
        accessor: 'mixlightSw',
        cellClass: ' w-25',
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: 'Uptime',
        accessor: 'uptime',
        cellClass: ' w-25',
      },
    ],
    [],
  );

  return (
    <>
      <Table
        style={{minHeight: '70vh'}}
        className="align-items-center table-flush"
        responsive
      >
        <TableDefined
          columns={cols}
          data={devices}
          setModalBasic={setModalBasic}
          setForDelete={setForDelete}
          setModalLarge={setModalLarge}
          setForModal={setForModal}
          handleRefresh={handleRefresh}
        />
      </Table>
    </>
  );
};

export default DevicesTable;
