import {yupResolver} from '@hookform/resolvers/yup';
import {apiFactory} from 'api';
import _ from 'lodash';
import React, {useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {
  Button,
  Card,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as yup from 'yup';
import 'yup-phone';

const validationSchema = yup.object().shape({
  name: yup.string().required('Camp obligatoriu'),
});

const TeamModal = ({
  modalOpen,
  toggleModal,
  handleRefresh,
  editDefaultValues,
}) => {
  const {register, handleSubmit, watch, setValue, errors, formState} = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => editDefaultValues, [editDefaultValues]),
  });

  const onSubmit = async (data) => {
    const updateTeam = async () => {
      await apiFactory().data.users().updateTeam(data, editDefaultValues.id);
      handleRefresh();
    };
    const createTeam = async () => {
      await apiFactory().data.users().createTeam({
        name: data.name,
      });
      handleRefresh();
    };

    if (_.isEmpty(editDefaultValues)) {
      createTeam();
    } else {
      updateTeam();
    }
    toggleModal();
  };

  useEffect(() => {
    setTimeout(() => {
      if (!_.isEmpty(editDefaultValues)) {
        for (var key of Object.keys(editDefaultValues)) {
          setValue(key, editDefaultValues[key]);
        }
      }
    });
  }, [editDefaultValues, modalOpen]);

  return (
    <Modal
      isOpen={modalOpen}
      toggle={toggleModal}
      wrapClassName="modal-right bg-secondary "
      backdrop="static"
    >
      <Card className="bg-secondary shadow border-0">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggleModal}>Client</ModalHeader>
          <ModalBody>
            <Label>Nume</Label>
            <Input
              innerRef={register}
              type="text"
              name="name"
              maxLength={255}
              placeholder=""
              className="form-control-alternative"
            />
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.name?.message}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              Renunță
            </Button>
            <Button color="primary" type="submit">
              Salvează
            </Button>{' '}
          </ModalFooter>
        </Form>
      </Card>
    </Modal>
  );
};

export default TeamModal;
