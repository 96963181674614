import { apiFactory } from "api";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import routes from "routes.js";
import ServiceWorkerWrapper from "../wrapper";

const Admin = (props) => {
  let history = useHistory();
  const mainContent = React.useRef(null);
  const [myUserInfo, setMyUserInfo] = useState([]);
  const location = useLocation();

  const fetchMyUserInfo = async () => {
    const userInfo = await apiFactory().data.auth().getMyUser();
    if (userInfo && userInfo.length === 0) {
      history.push("/auth/login");
    }
    setMyUserInfo(userInfo);
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    fetchMyUserInfo();
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.layout + prop.path} component={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  const filterRoutes = (routes, filteredArray) => {
    return routes.filter(
      ({ path, notPermitedRoleIds }) =>
        !filteredArray.includes(path) &&
        !notPermitedRoleIds.includes(myUserInfo[0].roleId)
    );
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    if (props.location.pathname.includes("necessities")) {
      return "Necesități";
    }
    if (props.location.pathname.includes("clockings")) {
      return "Pontaj";
    }
    return "Brand";
  };

  return (
    <>
      <ServiceWorkerWrapper />
      <Sidebar
        {...props}
        routes={myUserInfo.length > 0 ? filterRoutes(routes, ["/login"]) : []}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/TM_Prim_Logo.svg").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
          myUserInfo={myUserInfo}
        />

        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
        {/* <Container fluid>
          <AdminFooter />
        </Container> */}
      </div>
    </>
  );
};

export default Admin;
