import React from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';

import { Container, Row, Col, NavbarBrand } from 'reactstrap';
import AuthFooter from 'components/Footers/AuthFooter.js';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';

import routes from 'routes.js';
const logo = {
  innerLink: '/admin/index',
  imgSrc: require('../assets/img/brand/TM_Prim_Logo.svg').default,
  imgAlt: '...',
};

let navbarBrandProps;
if (logo && logo.innerLink) {
  navbarBrandProps = {
    to: logo.innerLink,
    tag: Link,
  };
} else if (logo && logo.outterLink) {
  navbarBrandProps = {
    href: logo.outterLink,
    target: '_blank',
  };
}

const FullPage = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/fullpage') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/fullpage/tv" />
        </Switch>
      </div>
    </>
  );
};

export default FullPage;
