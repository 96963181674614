export default (dateFrom, dateTo) => {
  var diff = {TotalMs: dateTo - dateFrom};
  diff.Days = Math.floor(diff.TotalMs / 86400000);

  var remHrs = diff.TotalMs % 86400000;
  var remMin = remHrs % 3600000;
  var remS = remMin % 60000;

  diff.Hours = Math.floor(remHrs / 3600000);
  diff.Minutes = Math.floor(remMin / 60000);
  diff.Seconds = Math.floor(remS / 1000);
  diff.Milliseconds = Math.floor(remS % 1000);

  diff.Years = Math.floor(diff.Days / 365);
  diff.Months = Math.floor((diff.Days % 365) / 30);
  diff.DaysExtended = Math.floor((diff.Days % 365) % 30);

  diff.stringFormatEntireDateShort = `${diff.Years}Y ${diff.Months}M ${diff.DaysExtended}D ${diff.Hours}H ${diff.Minutes}M ${diff.Seconds}S`;
  diff.stringFormatDaysDateShort = `${diff.Days}d ${diff.Hours}h ${diff.Minutes}m ${diff.Seconds}s`;

  return diff;
};
