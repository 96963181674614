import { apiFactory } from "api";
import { yupResolver } from "@hookform/resolvers/yup";
import { isDate, parse } from "date-fns";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as yup from "yup";
import "yup-phone";

function parseDateString(value, originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "yyyy-MM-dd", new Date());

  return parsedDate;
}

const validationSchema = yup.object().shape({
  name: yup.string().required("Camp obligatoriu"),
  quantity: yup
    .number()
    .required("Camp obligatoriu")
    .typeError("Trebuie sa fie un numar."),
  price: yup
    .number()
    .required("Camp obligatoriu")
    .typeError("Trebuie sa fie un numar."),
  deadline: yup.date().transform(parseDateString).required("Camp obligatoriu"),
  stock: yup
    .number()
    .typeError("Trebuie sa fie un numar.")
    .required("Camp obligatoriu"),
});

const objIncr = { count: 0 };

const NecessitiesModal = ({
  modalOpen,
  toggleModal,
  handleRefresh,
  editDefaultValues,
  projectId,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    errors,
    formState,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    defaultValues: useMemo(() => editDefaultValues, [editDefaultValues]),
  });

  const onSubmit = async (data) => {
    const updateUser = async () => {
      await apiFactory()
        .data.projects()
        .putNecessities(editDefaultValues.id, data);
      handleRefresh();
    };
    const createUser = async () => {
      await apiFactory().data.projects().postNecessities({
        name: data.name,
        quantity: data.quantity,
        price: data.price,
        deadline: data.deadline,
        stock: data.stock,
        delivery: 1,
        idProject: projectId,
      });
      handleRefresh();
    };

    if (_.isEmpty(editDefaultValues)) {
      createUser();
    } else {
      updateUser();
    }
    toggleModal();
  };

  useEffect(() => {
    setTimeout(() => {
      if (!_.isEmpty(editDefaultValues)) {
        for (var key of Object.keys(editDefaultValues)) {
          if (key === "deadline") {
            const curr = new Date(editDefaultValues[key]);
            curr.setDate(curr.getDate() + 1);
            const date = curr.toISOString().substr(0, 10);
            setValue(key, date);
          } else {
            setValue(key, editDefaultValues[key]);
          }
        }
      }
    });
  }, [editDefaultValues, modalOpen]);

  return (
    <Modal
      isOpen={modalOpen}
      toggle={toggleModal}
      wrapClassName='modal-right bg-secondary '
      backdrop='static'>
      <Card className='bg-secondary shadow border-0'>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggleModal}>Client</ModalHeader>
          <ModalBody>
            <Label>Nume</Label>
            <Input
              innerRef={register}
              type='text'
              name='name'
              maxLength={255}
              placeholder=''
              className='form-control-alternative'
            />
            <p className='text-danger font-weight-normal text-xs mt-2'>
              {errors.name?.message}
            </p>

            <Label>Cantitate</Label>
            <Input
              innerRef={register}
              type='text'
              name='quantity'
              placeholder=''
              className='form-control-alternative'
            />
            <p className='text-danger font-weight-normal text-xs mt-2'>
              {errors.quantity?.message}
            </p>

            <Label>Preț</Label>
            <Input
              innerRef={register}
              type='text'
              name='price'
              placeholder=''
              className='form-control-alternative'
            />
            <p className='text-danger font-weight-normal text-xs mt-2'>
              {errors.price?.message}
            </p>

            <Label>Deadline</Label>
            <Input
              innerRef={register}
              type='date'
              name='deadline'
              placeholder=''
              className='form-control-alternative'
            />
            <p className='text-danger font-weight-normal text-xs mt-2'>
              {errors.deadline?.message}
            </p>

            <Label>Stoc</Label>
            <Input
              innerRef={register}
              type='text'
              name='stock'
              placeholder=''
              className='form-control-alternative'
            />
            <p className='text-danger font-weight-normal text-xs mt-2'>
              {errors.stock?.message}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={toggleModal}>
              Renunță
            </Button>
            <Button color='primary' type='submit'>
              Salvează
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Card>
    </Modal>
  );
};

export default NecessitiesModal;
