import 'assets/scss/argon-dashboard-react.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/plugins/nucleo/css/nucleo.css';
import 'moment/locale/ro';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// serviceWorkerRegistration.register();
