//CUSTOM MAP COLORS

// export const mapStyles = [
//     {
//         'featureType': 'administrative',
//         'elementType': 'labels.text.fill',
//         'stylers': [
//             {
//                 'color': '#6195a0',
//             },
//         ],
//     },
//     {
//         'featureType': 'administrative.province',
//         'elementType': 'geometry.stroke',
//         'stylers': [
//             {
//                 'visibility': 'off',
//             },
//         ],
//     },
//     {
//         'featureType': 'landscape',
//         'elementType': 'geometry',
//         'stylers': [
//             {
//                 'lightness': '0',
//             },
//             {
//                 'saturation': '0',
//             },
//             {
//                 'color': '#f5f5f2',
//             },
//             {
//                 'gamma': '1',
//             },
//         ],
//     },
//     {
//         'featureType': 'landscape.man_made',
//         'elementType': 'all',
//         'stylers': [
//             {
//                 'lightness': '-3',
//             },
//             {
//                 'gamma': '1.00',
//             },
//         ],
//     },
//     {
//         'featureType': 'landscape.natural.terrain',
//         'elementType': 'all',
//         'stylers': [
//             {
//                 'visibility': 'off',
//             },
//         ],
//     },
//     {
//         'featureType': 'poi',
//         'elementType': 'all',
//         'stylers': [
//             {
//                 'visibility': 'off',
//             },
//         ],
//     },
//     {
//         'featureType': 'poi.park',
//         'elementType': 'geometry.fill',
//         'stylers': [
//             {
//                 'color': '#bae5ce',
//             },
//             {
//                 'visibility': 'on',
//             },
//         ],
//     },
//     {
//         'featureType': 'road',
//         'elementType': 'all',
//         'stylers': [
//             {
//                 'saturation': -100,
//             },
//             {
//                 'lightness': 45,
//             },
//             {
//                 'visibility': 'simplified',
//             },
//         ],
//     },
//     {
//         'featureType': 'road.highway',
//         'elementType': 'all',
//         'stylers': [
//             {
//                 'visibility': 'simplified',
//             },
//         ],
//     },
//     {
//         'featureType': 'road.highway',
//         'elementType': 'geometry.fill',
//         'stylers': [
//             {
//                 'color': '#fac9a9',
//             },
//             {
//                 'visibility': 'simplified',
//             },
//         ],
//     },
//     {
//         'featureType': 'road.highway',
//         'elementType': 'labels.text',
//         'stylers': [
//             {
//                 'color': '#4e4e4e',
//             },
//         ],
//     },
//     {
//         'featureType': 'road.arterial',
//         'elementType': 'labels.text.fill',
//         'stylers': [
//             {
//                 'color': '#787878',
//             },
//         ],
//     },
//     {
//         'featureType': 'road.arterial',
//         'elementType': 'labels.icon',
//         'stylers': [
//             {
//                 'visibility': 'off',
//             },
//         ],
//     },
//     {
//         'featureType': 'transit',
//         'elementType': 'all',
//         'stylers': [
//             {
//                 'visibility': 'simplified',
//             },
//         ],
//     },
//     {
//         'featureType': 'transit.station.airport',
//         'elementType': 'labels.icon',
//         'stylers': [
//             {
//                 'hue': '#0a00ff',
//             },
//             {
//                 'saturation': '-77',
//             },
//             {
//                 'gamma': '0.57',
//             },
//             {
//                 'lightness': '0',
//             },
//         ],
//     },
//     {
//         'featureType': 'transit.station.rail',
//         'elementType': 'labels.text.fill',
//         'stylers': [
//             {
//                 'color': '#43321e',
//             },
//         ],
//     },
//     {
//         'featureType': 'transit.station.rail',
//         'elementType': 'labels.icon',
//         'stylers': [
//             {
//                 'hue': '#ff6c00',
//             },
//             {
//                 'lightness': '4',
//             },
//             {
//                 'gamma': '0.75',
//             },
//             {
//                 'saturation': '-68',
//             },
//         ],
//     },
//     {
//         'featureType': 'water',
//         'elementType': 'all',
//         'stylers': [
//             {
//                 'color': '#eaf6f8',
//             },
//             {
//                 'visibility': 'on',
//             },
//         ],
//     },
//     {
//         'featureType': 'water',
//         'elementType': 'geometry.fill',
//         'stylers': [
//             {
//                 'color': '#c7eced',
//             },
//         ],
//     },
//     {
//         'featureType': 'water',
//         'elementType': 'labels.text.fill',
//         'stylers': [
//             {
//                 'lightness': '-49',
//             },
//             {
//                 'saturation': '-53',
//             },
//             {
//                 'gamma': '0.79',
//             },
//         ],
//     },
// ];


//GRAY MAP
// export const mapStyles = [
//     {
//         "featureType": "landscape",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "transit",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "poi",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "water",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "road",
//         "elementType": "labels.icon",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "stylers": [
//             {
//                 "hue": "#00aaff"
//             },
//             {
//                 "saturation": -100
//             },
//             {
//                 "gamma": 2.15
//             },
//             {
//                 "lightness": 12
//             }
//         ]
//     },
//     {
//         "featureType": "road",
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "visibility": "on"
//             },
//             {
//                 "lightness": 24
//             }
//         ]
//     },
//     {
//         "featureType": "road",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "lightness": 57
//             }
//         ]
//     }
// ]



// CUSTOM MAP 2
export const mapStyles = [
    {
        "featureType": "all",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "color": "#cee9de"
            },
            {
                "saturation": "2"
            },
            {
                "weight": "0.80"
            }
        ]
    },
    {
        "featureType": "poi.attraction",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#f5d6d6"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.icon",
        "stylers": [
            {
                "hue": "#ff0000"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "hue": "#0064ff"
            },
            {
                "gamma": "1.44"
            },
            {
                "lightness": "-3"
            },
            {
                "weight": "1.69"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "weight": "0.31"
            },
            {
                "gamma": "1.43"
            },
            {
                "lightness": "-5"
            },
            {
                "saturation": "-22"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "hue": "#ff0000"
            }
        ]
    },
    {
        "featureType": "transit.station.airport",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "hue": "#ff0045"
            }
        ]
    },
    {
        "featureType": "transit.station.bus",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "hue": "#00d1ff"
            }
        ]
    },
    {
        "featureType": "transit.station.bus",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "transit.station.rail",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "hue": "#00cbff"
            }
        ]
    },
    {
        "featureType": "transit.station.rail",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#46bcec"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "weight": "1.61"
            },
            {
                "color": "#cde2e5"
            },
            {
                "visibility": "on"
            }
        ]
    }
]



// BLACK THEME
// export const mapStyles = [
//     {
//         "featureType": "all",
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "saturation": 36
//             },
//             {
//                 "color": "#000000"
//             },
//             {
//                 "lightness": 40
//             }
//         ]
//     },
//     {
//         "featureType": "all",
//         "elementType": "labels.text.stroke",
//         "stylers": [
//             {
//                 "visibility": "on"
//             },
//             {
//                 "color": "#000000"
//             },
//             {
//                 "lightness": 16
//             }
//         ]
//     },
//     {
//         "featureType": "all",
//         "elementType": "labels.icon",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#000000"
//             },
//             {
//                 "lightness": 20
//             }
//         ]
//     },
//     {
//         "featureType": "administrative",
//         "elementType": "geometry.stroke",
//         "stylers": [
//             {
//                 "color": "#000000"
//             },
//             {
//                 "lightness": 17
//             },
//             {
//                 "weight": 1.2
//             }
//         ]
//     },
//     {
//         "featureType": "administrative",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative.country",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "simplified"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative.country",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "visibility": "simplified"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative.country",
//         "elementType": "labels.text",
//         "stylers": [
//             {
//                 "visibility": "simplified"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative.province",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative.locality",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "simplified"
//             },
//             {
//                 "saturation": "-100"
//             },
//             {
//                 "lightness": "30"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative.neighborhood",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative.land_parcel",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "landscape",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "simplified"
//             },
//             {
//                 "gamma": "0.00"
//             },
//             {
//                 "lightness": "74"
//             }
//         ]
//     },
//     {
//         "featureType": "landscape",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#000000"
//             },
//             {
//                 "lightness": 20
//             }
//         ]
//     },
//     {
//         "featureType": "landscape.man_made",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "lightness": "3"
//             }
//         ]
//     },
//     {
//         "featureType": "poi",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "poi",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#000000"
//             },
//             {
//                 "lightness": 21
//             }
//         ]
//     },
//     {
//         "featureType": "road",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "visibility": "simplified"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#000000"
//             },
//             {
//                 "lightness": 17
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway",
//         "elementType": "geometry.stroke",
//         "stylers": [
//             {
//                 "color": "#000000"
//             },
//             {
//                 "lightness": 29
//             },
//             {
//                 "weight": 0.2
//             }
//         ]
//     },
//     {
//         "featureType": "road.arterial",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#000000"
//             },
//             {
//                 "lightness": 18
//             }
//         ]
//     },
//     {
//         "featureType": "road.local",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#000000"
//             },
//             {
//                 "lightness": 16
//             }
//         ]
//     },
//     {
//         "featureType": "transit",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#000000"
//             },
//             {
//                 "lightness": 19
//             }
//         ]
//     },
//     {
//         "featureType": "water",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#000000"
//             },
//             {
//                 "lightness": 17
//             }
//         ]
//     }
// ]