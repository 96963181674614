import { css } from "@emotion/core";
import { apiFactory } from "api";
import classnames from "classnames";
import DatatablePagination from "components/DatatablePagination";
import NecessitiesModal from "components/Modals/NecessitiesModal.js";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useLocation, useHistory } from 'react-router-dom';
import { checkUserPermissions } from 'utils/authHelpers';
import { SyncLoader } from "react-spinners";
import { usePagination, useSortBy, useTable } from "react-table";
import ReactLoading from "react-loading";

import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Modal,
  Row,
  Table,
} from "reactstrap";

const override = css`
  display: block;
  margin: 2rem auto;
  border-color: red;
`;

function TableDefined({
  columns,
  data,
  divided = false,
  defaultPageSize = 10,
  handleRefresh,
  getNecessities,
  projectId,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: defaultPageSize },
    },
    useSortBy,
    usePagination
  );

  const [modal, setModal] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editValues, setEditValues] = useState({});

  const invoiceFunction = async () => {
    await apiFactory().data.clockings().updateInvoiceClocking(invoice);
    getNecessities();
    setModal(!modal);
  };

  const truncString = (string) => {
    if (string && string.length >= 27) {
      return string.slice(0, 27) + "...";
    }
    return string || "";
  };

  const openEditModal = (newData, id) => {
    setModalOpen(!modalOpen);
    setEditValues({ ...newData, id: id });
  };

  return (
    <>
      <NecessitiesModal
        modalOpen={modalOpen}
        toggleModal={() => {
          setModalOpen(!modalOpen);
        }}
        projectId={projectId}
        handleRefresh={getNecessities}
        editDefaultValues={editValues}
      />
      <Table
        {...getTableProps()}
        className={`r-table table ${classnames({ "table-divided": divided })}`}
        responsive>
        <thead className='thead-light'>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={`th_${columnIndex}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sorted-desc"
                        : "sorted-asc"
                      : ""
                  }>
                  {column.render("Header")}
                  <span />
                </th>
              ))}
              <th scope='col' />
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <>
                      <td
                        key={`td_${cellIndex}`}
                        {...cell.getCellProps({
                          className: cell.column.cellClass,
                        })}
                        // style={
                        //   cell.column.cellClass === "list-item-heading w-10"
                        //     ? { verticalAlign: "middle" }
                        //     : { verticalAlign: "middle" }
                        // }
                      >
                        {cell.column.id === "deadline" ? (
                          <Moment locale='ro' format='DD MMMM, YYYY'>
                            {cell.value}
                          </Moment>
                        ) : (
                          truncString(cell.value, 30)
                        )}
                      </td>
                    </>
                  );
                })}
                <td key={`td_5`} style={{ minWidth: "120px" }}>
                  <div className='d-flex justify-content-end'>
                    <button
                      className='btn btn-outline-primary mr-1 btn-sm'
                      onClick={() => {
                        openEditModal(row.values, row.original.id);
                      }}>
                      <i className='ni ni-settings pr-2' />
                      Editează
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <DatatablePagination
        page={pageIndex}
        pages={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        pageSizeOptions={[5, 10, 20, 30, 40, 50]}
        showPageSizeOptions={true}
        showPageJump={false}
        defaultPageSize={pageSize}
        onPageChange={(p) => gotoPage(p)}
        onPageSizeChange={(s) => setPageSize(s)}
        paginationMaxSize={5}
      />
    </>
  );
}

const NecessitiesTable = ({ necessities, getNecessities, projectId }) => {
  const cols = React.useMemo(() => [
    {
      Header: "Id",
      accessor: "id",
      cellClass: "list-item-heading",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Nume",
      accessor: "name",
      cellClass: "text-muted",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Cantitate",
      accessor: "quantity",
      cellClass: "text-muted",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Pret",
      accessor: "price",
      cellClass: "text-muted",
      Cell: (props) => (
        <>{<Moment format='YYYY/MM/DD'>{props.value}</Moment>}</>
      ),
    },
    {
      Header: "Stoc",
      accessor: "stock",
      cellClass: "text-muted",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Deadline",
      accessor: "deadline",
      cellClass: "text-muted",
      Cell: (props) => <>{props.value}</>,
    },
  ]);
  return (
    <>
      <TableDefined
        columns={cols}
        data={necessities}
        getNecessities={getNecessities}
        projectId={projectId}
        // divided
      />
    </>
  );
};

const Necessities = () => {
  const [necessities, setNecessities] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const projectId = location.pathname.slice(27);
  const [project, setProject] = useState(null);
  
  const fetchCurrentProject = async () => {
    const newProject = await apiFactory()
      .data.projects()
      .getProjectById(projectId);
    setProject(newProject[0]);
  };

  const [isAuthorized, setIsAuthorized] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const authorize = async () => {
      const authorized = await checkUserPermissions(location.pathname, history);
      if (authorized) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    };
    authorize();
  }, [location.pathname, history]);

  useEffect(() => {
    if (isAuthorized) {
      fetchCurrentProject();
      getNecessities();
    }
  }, [isAuthorized]);

  const getNecessities = async () => {
    const newNecessities = await apiFactory()
      .data.projects()
      .getNecessities(projectId);
    setNecessities(newNecessities);
  };
  
  if (!isAuthorized) {
    return (
      <Container fluid>
        <Row>
          <Col className="text-center">
            <h1>Not Authorized</h1>
            <p>You do not have permission to view this page.</p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      {!necessities || !project ? (
        <div
          className='d-flex flex-direction-row justify-content-center align-items-center align-content-center'
          style={{ height: "90vh" }}>
          <ReactLoading
            type={"spinningBubbles"}
            color={"#404E68"}
            height={"70px"}
            width={"70px"}
          />
        </div>
      ) : (
        <Row>
          <Col xxs='12' className='mb-4 mt-4'>
            <Card className='mb-4 shadow'>
              <CardHeader className='border-0'>
                {/* <Row>
                <Col xs="6">
                  <h3 className="mb-0">Necesități</h3>
                </Col>
              </Row> */}

                <div className='d-flex justify-content-between'>
                  <h2 className='mb-0'>
                    Necesități pentru proiectul{" "}
                    <span style={{ color: "#00AABC" }}> {project.name}</span>
                  </h2>
                  <button
                    type='button'
                    className='btn btn-primary btn-sm'
                    onClick={() => setModalOpen(true)}>
                    Adaugă o necesitate
                  </button>
                </div>
              </CardHeader>
              {necessities === null ? (
                <SyncLoader
                  color={"#404E68"}
                  css={override}
                  size={50}
                  margin={2}
                />
              ) : necessities.length === 0 ? (
                <div
                  className='flex-column d-flex w-100 justify-content-center align-item-center'
                  style={{ height: "10vh" }}>
                  <div className='flex-row d-flex justify-content-center align-item-center'>
                    <div
                      className={
                        "flex-column d-flex justify-content-center align-item-center "
                      }>
                      <p
                        className='align-item-center justify-content-center d-flex text-lmedium  font-weight-semibold'
                        style={{ textAlign: "center" }}>
                        Deocamdata nu există necesități.
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <NecessitiesTable
                  necessities={necessities}
                  getNecessities={getNecessities}
                  projectId={projectId}
                />
              )}
            </Card>
          </Col>
        </Row>
      )}

      <NecessitiesModal
        modalOpen={modalOpen}
        toggleModal={() => {
          setModalOpen(!modalOpen);
        }}
        projectId={projectId}
        handleRefresh={getNecessities}
      />
    </Container>
  );
};

export default Necessities;
