import React, { useState, useEffect, useMemo } from "react";
import * as yup from "yup";
import "yup-phone";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { parse, isDate } from "date-fns";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  Card,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { apiFactory } from "api";

function parseDateString(value, originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "yyyy-MM-dd", new Date());
  return parsedDate;
}

const validationSchema = yup.object().shape({
  name: yup.string().required("Camp obligatoriu"),
  comment: yup.string(),
  startDate: yup.date().transform(parseDateString).required("Camp obligatoriu"),
  deadlineDate: yup
    .date()
    .transform(parseDateString)
    .required("Camp obligatoriu"),
});

const TaskModal = ({
  modalOpen,
  toggleModal,
  handleRefresh,
  editDefaultValues,
  projectId,
  projectName,
  viewOnly,
}) => {
  const { register, handleSubmit, errors, setValue, control, watch } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    defaultValues: useMemo(() => editDefaultValues, [editDefaultValues]),
  });
  const watchedStartDate = watch("startDate");
  const [users, setUsers] = useState([]);
  const [taskState, setTaskState] = useState([]);
  const [modifiedDeadlineDate, setModifiedDeadlineDate] = useState(false);

  const onSubmit = async (data) => {
    data.startDate = moment(data.startDate);
    data.deadlineDate = moment(data.deadlineDate);

    const createTask = async () => {
      await apiFactory().data.projects().createTask({
        name: data.name,
        startDate: data.startDate,
        deadlineDate: data.deadlineDate,
        userId: data.userId,
        stateId: data.stateId,
        projectId: projectId,
      });
      handleRefresh();
    };
    const updateTask = async () => {
      await apiFactory().data.projects().updateTask(
        {
          name: data.name,
          startDate: data.startDate,
          deadlineDate: data.deadlineDate,
          userId: data.userId,
          stateId: data.stateId,
          projectId: projectId,
        },
        editDefaultValues.id
      );
      handleRefresh();
    };

    if (_.isEmpty(editDefaultValues)) {
      createTask();
    } else {
      updateTask();
    }

    toggleModal();
  };

  useEffect(() => {
    setTimeout(() => {
      if (!_.isEmpty(editDefaultValues)) {
        for (var key of Object.keys(editDefaultValues)) {
          if (key === "startDate" || key === "deadlineDate") {
            setValue(key, new Date(editDefaultValues[key]));
          } else {
            setValue(key, editDefaultValues[key]);
          }
        }
      }
    });
  }, [editDefaultValues, modalOpen]);
  useEffect(() => {
    const newDate = new Date(watchedStartDate);
    newDate.setHours(newDate.getHours() + 1);
    if (!modifiedDeadlineDate) setValue("deadlineDate", newDate);
  }, [watchedStartDate]);

  const fetchNewUsers = async () => {
    const newUsers = await apiFactory().data.users().getUsers();
    setUsers(newUsers);
  };
  const fetchTaskStatus = async () => {
    const newTaskStatus = await apiFactory().data.projects().getStatusTasks();
    setTaskState(newTaskStatus);
  };

  useEffect(() => {
    fetchNewUsers();
    fetchTaskStatus();
  }, []);

  return (
    <Modal
      isOpen={modalOpen}
      toggle={toggleModal}
      wrapClassName='modal-right bg-secondary '
      backdrop='static'>
      <Card className='bg-secondary shadow border-0'>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggleModal}>
            Adauga/Editeaza task nou
            <h3>
              Proiect: <span style={{ color: "#00AABC" }}> {projectName}</span>
            </h3>
          </ModalHeader>
          <ModalBody>
            <Label>Task</Label>
            <Input
              innerRef={register}
              type='text'
              name='name'
              disabled={viewOnly}
              maxLength={255}
              placeholder=''
              className='form-control-alternative'
            />
            <p className='text-danger font-weight-normal text-xs mt-2'>
              {errors.name?.message}
            </p>
            <Label>Start Date</Label>
            <Controller
              name='startDate'
              control={control}
              render={({ value }) => (
                <DatePicker
                  selected={value}
                  onChange={(date) => {
                    setValue("startDate", date);
                  }}
                  showTimeSelect
                  dateFormat='MMMM d, yyyy h:mm aa'
                  className='form-control-alternative form-control d-flex'
                />
              )}
            />

            <p className='text-danger font-weight-normal text-xs mt-2'>
              {errors.startDate?.message}
            </p>

            <Label>Deadline Date</Label>
            <Controller
              name='deadlineDate'
              control={control}
              render={({ value }) => (
                <DatePicker
                  selected={value}
                  onChange={(date) => {
                    setValue("deadlineDate", date);
                    setModifiedDeadlineDate(true);
                  }}
                  showTimeSelect
                  dateFormat='MMMM d, yyyy h:mm aa'
                  className='form-control-alternative form-control d-flex'
                />
              )}
            />
            <p className='text-danger font-weight-normal text-xs mt-2'>
              {errors.deadlineDate?.message}
            </p>
            <Label>Utilizator</Label>
            <Input
              innerRef={register}
              type='select'
              disabled={viewOnly}
              name='userId'
              className='form-control-alternative '>
              {users &&
                users.map((element) => {
                  return (
                    <option value={element.id} key={element.id}>
                      {element.firstName + " " + element.lastName}
                    </option>
                  );
                })}
            </Input>
            <p className='text-danger font-weight-normal text-xs mt-2'></p>
            <Label>Stare Task</Label>
            <Input
              innerRef={register}
              type='select'
              disabled={viewOnly}
              name='stateId'
              className='form-control-alternative'>
              {taskState &&
                taskState.map((element) => {
                  return (
                    <option value={element.id} key={element.id}>
                      {element.name}
                    </option>
                  );
                })}
            </Input>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={toggleModal}>
              Renunță
            </Button>
            {viewOnly ? null : (
              <Button color='primary' type='submit'>
                Salvează
              </Button>
            )}{" "}
          </ModalFooter>
        </Form>
      </Card>
    </Modal>
  );
};

export default TaskModal;
