import { apiFactory } from "api";
import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Input,
} from "reactstrap";
import TaskModal from "../Modals/TaskModal";

let colorMap = {
  1: "#ffffff",
  2: "#0099ff",
  3: "#cc00ff",
  4: "#ccccff",
};

const TaskCard = ({ task, taskStates, handleRefresh, projectId, projectName }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [selectedStatus, setSelectedStatus] = useState(task.stateId);

  const handleChange = async (event) => {
    const selected = taskStates.filter(
      (task) => task.id === parseInt(event.target.value)
    )[0].id;
    setSelectedStatus(selected);
    await apiFactory().data.tasks().putUpdateTaskStatus(task.id, selected);
    handleRefresh();
  };

  const openEditModal = (task) => {
    setModalOpen(!modalOpen);
    setEditValues(task);
  };
  return (
    <>
      <TaskModal
        projectId={projectId}
        projectName={projectName}
        modalOpen={modalOpen}
        toggleModal={() => {
          setModalOpen(!modalOpen);
        }}
        handleRefresh={handleRefresh}
        editDefaultValues={editValues}
        viewOnly={false}
      />
      <Col className="order-xl-1 mt-4 flex-grow" xl="3" style={{ minHeight: "300px" }}>
        <Card className="shadow h-100">
          <CardHeader style={{ backgroundColor: `${colorMap[selectedStatus]}`,  color: colorMap[selectedStatus] === "#ffffff" ? "black" : "white" }}>
            <Row className="align-items-center">
              <Col xs="6">
                <h3
                  className="mb-0"
                  style={{
                    color: colorMap[selectedStatus] === "#ffffff" ? "black" : "white",
                  }}>
                  {task.name}
                </h3>
              </Col>
              <Col className="text-right d-flex justify-content-end" xs="6">
                <p
                  className="text-xs font-weight-400 mb-0 mr-4"
                  style={{
                    textAlign: "justify",
                    textJustify: "inter-word",
                    color: colorMap[selectedStatus] === "#ffffff" ? "black" : "white",
                  }}>
                  {task.firstName + " " + task.lastName}
                </p>
                <i
                  className="fas fa-edit"
                  style={{
                    cursor: "pointer",
                    color: colorMap[task.stateId] === "#ffffff" ? "black" : "white",
                  }}
                  onClick={() => openEditModal(task)}></i>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
           {/*  <p
              className="text-muted mb-4 text-xs font-weight-200"
              style={{ textAlign: "justify", textJustify: "inter-word" }}>
              {task.comment}
            </p> */}
            <p
              className="mb-1 text-sm font-weight-600"
              style={{ textAlign: "justify", textJustify: "inter-word" }}>
              Timp:
            </p>
            <p
              className="text-muted mb-1 text-sm font-weight-400"
              style={{ textAlign: "justify", textJustify: "inter-word" }}>
              {format(new Date(task.startDate), "MMM. dd-yyy (HH:mm)")}
            </p>
            <p
              className="text-muted mb-1 text-sm font-weight-400"
              style={{ textAlign: "justify", textJustify: "inter-word" }}>
              {format(new Date(task.deadlineDate), "MMM. dd-yyy (HH:mm)")}
            </p>
          </CardBody>
          <CardFooter>
            <div className="d-flex justify-content-between align-items-center">
              {taskStates !== null &&
              selectedStatus !== null &&
              taskStates.filter((task) => task.id === selectedStatus).length > 0 ? (
                <Badge color="" className="badge-dot mr-4">
                  <i className="bg-warning" />
                  {taskStates.filter((task) => task.id === selectedStatus)[0].name}
                </Badge>
              ) : null}
              <Input
                type="select"
                name="roleId"
                className="form-control"
                value={selectedStatus}
                bsSize="sm"
                onChange={(e) => handleChange(e)}>
                {taskStates !== null &&
                  taskStates.map((state) => {
                    return (
                      <option value={state.id} key={"role_" + state.id}>
                        {state.name}
                      </option>
                    );
                  })}
              </Input>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </>
  );
};

export default TaskCard;
