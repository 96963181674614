import {auth} from './auth';
import {clients} from './clients';
import {clockings} from './clockings';
import {devices} from './devices';
import {lightPoints} from './lights';
import {projects} from './projects';
import {tasks} from './tasks';
import {users} from './users';

export const apiFactory = () => ({
  data: {
    auth: () => auth(),
    lightPoints: () => lightPoints(),
    users: () => users(),
    projects: () => projects(),
    clockings: () => clockings(),
    clients: () => clients(),
    tasks: () => tasks(),
    devices: () => devices(),
  },
});

export type ApiFactory = ReturnType<typeof apiFactory>;
