import {css} from '@emotion/core';
import {apiFactory} from 'api';
import classnames from 'classnames';
import DatatablePagination from 'components/DatatablePagination';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import ReactLoading from 'react-loading';
import { useLocation, useHistory } from 'react-router-dom';
import { checkUserPermissions } from 'utils/authHelpers';
import {usePagination, useSortBy, useTable} from 'react-table';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Row,
  Table,
} from 'reactstrap';
import '../../assets/css/style.css';

const localizer = momentLocalizer(moment);
const override = css`
  display: block;
  margin: 2rem auto;
  border-color: red;
`;

function TableDefined({
  columns,
  data,
  divided = false,
  defaultPageSize = 10,
  handleRefresh,
  getProjects,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0, pageSize: defaultPageSize},
    },
    useSortBy,
    usePagination,
  );

  const [modal, setModal] = useState(false);
  const [invoice, setInvoice] = useState(null);

  const invoiceFunction = async () => {
    await apiFactory().data.clockings().putInvoiceByUser({
      idUser: invoice.idUser,
      idProject: invoice.idProject,
    });
    getProjects();
    setModal(!modal);
  };

  const truncString = (string) => {
    if (string && string.length >= 27) {
      return string.slice(0, 27) + '...';
    }
    return string || '';
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <div className="modal-header">
          <h3
            className="modal-title"
            id="modal-title-default"
            style={{fontSize: '1rem'}}
          >
            Facturare
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setModal(!modal)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p className="mb-0">Dorești sa facturezi selecția?</p>
        </div>
        <div className="modal-footer">
          <Button
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setModal(!modal)}
          >
            Nu
          </Button>
          <Button
            color="primary"
            type="button"
            className="ml-auto"
            onClick={() => {
              invoiceFunction();
            }}
          >
            Da
          </Button>
        </div>
      </Modal>
      <Table
        {...getTableProps()}
        className={`r-table table ${classnames({'table-divided': divided})}`}
        responsive
      >
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={`th_${columnIndex}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'sorted-desc'
                        : 'sorted-asc'
                      : ''
                  }
                >
                  {column.render('Header')}
                  <span />
                </th>
              ))}
              <th scope="col" />
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <>
                      <td
                        key={`td_${cellIndex}`}
                        {...cell.getCellProps({
                          className: cell.column.cellClass,
                        })}
                      >
                        {cell.column.id === 'totalHours'
                          ? cell.value
                          : truncString(cell.value, 30)}
                      </td>
                    </>
                  );
                })}
                <td key={`td_5`} style={{minWidth: '120px'}}>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-outline-primary mr-1 btn-sm"
                      disabled={row.original.totalHours === 0}
                      onClick={() => {
                        setInvoice(row.original);
                        setModal(!modal);
                      }}
                    >
                      <i className="fas fa-th-list mr-2" />
                      Facturează
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <DatatablePagination
        page={pageIndex}
        pages={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        pageSizeOptions={[5, 10, 20, 30, 40, 50]}
        showPageSizeOptions={true}
        showPageJump={false}
        defaultPageSize={pageSize}
        onPageChange={(p) => gotoPage(p)}
        onPageSizeChange={(s) => setPageSize(s)}
        paginationMaxSize={5}
      />
    </>
  );
}

const ClockingTable = ({usersStats, getProjects}) => {
  const cols = React.useMemo(() => [
    /*  {
      Header: "Id",
      accessor: "id",
      cellClass: "list-item-heading",
      Cell: (props) => <>{props.value}</>,
    }, */
    {
      Header: 'FirstName',
      accessor: 'firstName',
      cellClass: 'text-muted',
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: 'LastName',
      accessor: 'lastName',
      cellClass: 'text-muted',
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: 'Ore Nefacturate',
      accessor: 'totalHours',
      cellClass: 'text-muted',
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: 'Echipa',
      accessor: 'team',
      cellClass: 'text-muted',
      Cell: (props) => <>{props.value}</>,
    },
  ]);
  return (
    <>
      <TableDefined
        columns={cols}
        data={usersStats}
        getProjects={getProjects}
        // divided
      />
    </>
  );
};

const ClockingProject = () => {
  const location = useLocation();
  const [project, setProject] = useState(null);
  const [teams, setTeams] = useState([]);
  const [projectStats, setProjectStats] = useState(null);
  const [usersStats, setUserStats] = useState(null);
  const [clockings, setClockings] = useState(null);
  const [modal, setModal] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const projectID = location.pathname.slice(25);
  const [dropdownOpenTeam, setDropdownOpenTeam] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState({
    id: 0,
    name: 'Selectează Echipa',
  });

  const [isAuthorized, setIsAuthorized] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const authorize = async () => {
      const authorized = await checkUserPermissions(location.pathname, history);
      if (authorized) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    };
    authorize();
  }, [location.pathname, history]);

  useEffect(() => {
    if (isAuthorized) {
      getProjects();
      fetchCurrentProject();
      fetchTeams();
    }
  }, [isAuthorized], [selectedTeam]);

  const toggleTeam = () => setDropdownOpenTeam((prevState) => !prevState);

  const fetchTeams = async () => {
    const teamsResponse = await apiFactory().data.users().getTeams();
    setTeams(teamsResponse);
  };

  const invoiceFunction = async () => {
    await apiFactory().data.clockings().putInvoiceByUser({
      // idUser: invoice.id,
      idProject: projectStats.idProject,
    });
    getProjects();
    setModal(!modal);
  };

  const fetchCurrentProject = async () => {
    const newProject = await apiFactory()
      .data.projects()
      .getProjectById(projectID);
    setProject(newProject[0]);
  };

  const getProjects = async () => {
    const statsProject = await apiFactory()
      .data.clockings()
      .getPorjectStatisticsClocking(projectID);

    const clockings = await apiFactory()
      .data.clockings()
      .getClockings(projectID, 0);

    const users = await apiFactory().data.projects().getProjectUsers(projectID);
    const usersStats = await apiFactory()
      .data.clockings()
      .getUsersStatisticsClocking(projectID, selectedTeam.id);

    /* let allUserInfo = [];
    users.map((user) => {
      let additonalInfo = usersStats.filter((x) => x.idUser === user.id);
      if (additonalInfo.length !== 0) {
        let totalHours = additonalInfo[0].totalHours;
        allUserInfo.push({
          ...user,
          totalHours,
        });
      } else {
        allUserInfo.push({
          ...user,
          totalHours: 0,
        });
      }
    }); */

    const newClockings = clockings.map((clocking) => {
      return {
        start: moment(clocking.start).toDate(),
        end: moment(clocking.stop).toDate(),
        title: clocking.project,
      };
    });

    setClockings(newClockings);
    setUserStats(usersStats);
    setProjectStats(statsProject[0]);
  };

  if (!isAuthorized) {
    return (
      <Container fluid>
        <Row>
          <Col className="text-center">
            <h1>Not Authorized</h1>
            <p>You do not have permission to view this page.</p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      {projectStats === null ||
      project === null ||
      clockings === null ||
      usersStats === null ? (
        <div
          className="d-flex flex-direction-row justify-content-center align-items-center align-content-center"
          style={{height: '90vh'}}
        >
          <ReactLoading
            type={'spinningBubbles'}
            color={'#404E68'}
            height={'70px'}
            width={'70px'}
          />
        </div>
      ) : (
        <>
          <Modal
            className="modal-dialog-centered"
            isOpen={modal}
            toggle={() => setModal(!modal)}
          >
            <div className="modal-header">
              <h3
                className="modal-title"
                id="modal-title-default"
                style={{fontSize: '1rem'}}
              >
                Facturare
              </h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModal(!modal)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="mb-0">Dorești sa facturezi selecția?</p>
            </div>
            <div className="modal-footer">
              <Button
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => setModal(!modal)}
              >
                Nu
              </Button>
              <Button
                color="primary"
                type="button"
                className="ml-auto"
                onClick={() => {
                  invoiceFunction();
                }}
              >
                Da
              </Button>
            </div>
          </Modal>
          <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
            <Container fluid>
              <div className="header-body">
                {/* Card stats */}
                <Row className='justify-content-between d-flex align-items-stretch"'>
                  <Col lg="6" xl="3" className="h-100">
                    <Card className="card-stats mb-4 mb-xl-0 h-100">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Proiect Actual
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {project.name}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                              <i className="ni ni-briefcase-24" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3" className="h-100">
                    <Card className="card-stats mb-4 mb-xl-0 h-100">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Ore nefacturate
                            </CardTitle>
                            <span className="h2 fo1nt-weight-bold mb-0">
                              {projectStats && projectStats.length !== 0
                                ? projectStats.totalHours
                                : '-'}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i className="fas fa-chart-bar" />
                            </div>
                          </Col>
                        </Row>
                        {projectStats && projectStats.length !== 0 ? (
                          <Button
                            size="sm"
                            className="mt-3 mb-0"
                            onClick={() => {
                              setModal(!modal);
                            }}
                          >
                            <i className="fas fa-th-list mr-2" /> Facturează
                          </Button>
                        ) : null}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Container fluid className="mt--7">
            <Row>
              <Col xxs="12" className="mb-4 mt-4">
                <Card className="mb-4 shadow">
                  <CardHeader className="border-0">
                    <Row>
                      <Col xs="6">
                        <h3 className="mb-0">Statistici utilizatori</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Dropdown
                    isOpen={dropdownOpenTeam}
                    toggle={toggleTeam}
                    className="m-2"
                  >
                    <DropdownToggle caret>{selectedTeam.name}</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        key={0}
                        onClick={() => {
                          setSelectedTeam({
                            id: 0,
                            name: 'Selecteaza Echipa',
                          });
                        }}
                      >
                        Selecteaza Echipa
                      </DropdownItem>
                      {teams.map((x) => (
                        <DropdownItem
                          key={x.id}
                          onClick={() => {
                            setSelectedTeam(x);
                          }}
                        >
                          {x.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>{' '}
                  <ClockingTable
                    usersStats={usersStats}
                    getProjects={getProjects}
                  />
                </Card>
              </Col>

              <Col xxs="12" xl="12" className="mb-4 flex-grow">
                <Card className="mb-4 shadow h-100 ">
                  <CardBody className=" h-100">
                    <Calendar
                      localizer={localizer}
                      defaultDate={new Date()}
                      defaultView="month"
                      events={clockings}
                      startAccessor="start"
                      endAccessor="end"
                      style={{fontSize: 11, height: '50vh'}}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default ClockingProject;
