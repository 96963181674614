import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import FullPageLayout from 'layouts/FullPage.js';

import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useEffect } from 'react';

const App = () => {
  const history = useHistory();

  useEffect(() => {
    history.listen((location, action) => {
      // check for sw updates on page change
      navigator.serviceWorker
        .getRegistrations()
        .then((regs) => regs.forEach((reg) => reg.update()));
    });
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route
          path="/fullpage"
          render={(props) => <FullPageLayout {...props} />}
        />
        <Redirect from="/" to="/admin/index" />
      </Switch>
    </>
  );
};

export default App;
