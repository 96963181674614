import { useMediaQuery } from "beautiful-react-hooks";
import React, { useEffect, useState } from "react";
import { Card, CardHeader, Col, Row, CardBody, Container } from "reactstrap";
import ProjectCard from "components/Cards/ProjectCard.js";
import ProjectModal from "components/Modals/ProjectModal.js";
import { apiFactory } from "api";
import ReactLoading from "react-loading";
import { useLocation, useHistory } from 'react-router-dom';
import { checkUserPermissions } from 'utils/authHelpers';

const Projects = () => {
  const isSmall = useMediaQuery("(max-width: 57rem)");
  const [modalOpen, setModalOpen] = useState(false);
  const [projects, setProjects] = useState(null);
  const [clients, setClients] = useState([]);
  const [projectState, setProjectState] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const authorize = async () => {
      const authorized = await checkUserPermissions(location.pathname, history);
      if (authorized) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    };
    authorize();
  }, [location.pathname, history]);

  useEffect(() => {
    if (isAuthorized) {
      fetchNewClients();
      fetchProjectState();
      fetchNewprojects();
    }
  }, [isAuthorized]);

  const fetchNewprojects = async () => {
    const newProjects = await apiFactory().data.projects().getProjects();
    if (newProjects.message) {
      alert(newProjects.message);
    } else {
      setProjects(newProjects);
    }
  };

  const handleRefresh = () => {
    fetchNewprojects();
  };

  const fetchNewClients = async () => {
    const newclients = await apiFactory().data.clients().getClients();
    setClients(newclients);
  };
  const fetchProjectState = async () => {
    const newProjectsStates = await apiFactory()
      .data.projects()
      .getStateProjects();
    setProjectState(newProjectsStates);
  };

  if (!isAuthorized) {
    return (
      <Container fluid>
        <Row>
          <Col className="text-center">
            <h1>Not Authorized</h1>
            <p>You do not have permission to view this page.</p>
          </Col>
        </Row>
      </Container>
    );
  }

  
  return (
    <Container fluid>
      {!projects ? (
        <div
          className="d-flex flex-direction-row justify-content-center align-items-center align-content-center"
          style={{ height: "90vh" }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#404E68"}
            height={"70px"}
            width={"70px"}
          />
        </div>
      ) : (
        <Row>
          {projects && projects.length > 0 ? (
            <Col xxs="12" className="mb-4 m-4">
              <Card className="shadow">
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h2 className="mb-0">Proiecte</h2>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => setModalOpen(true)}
                    >
                      Adauga proiect nou
                    </button>
                  </div>
                </CardBody>
              </Card>
              <Row className="d-flex align-items-stretch">
                {projects.map((project) => (
                  <ProjectCard
                    clients={clients}
                    projectState={projectState}
                    key={`project_${project.id}`}
                    project={project}
                    handleRefresh={handleRefresh}
                  />
                ))}
              </Row>
            </Col>
          ) : (
            <div
              className="flex-column d-flex w-100 justify-content-center align-item-center"
              style={{ height: "80vh" }}
            >
              <div className="flex-row d-flex justify-content-center align-item-center">
                <div
                  className={
                    "flex-column d-flex justify-content-center align-item-center " +
                    (isSmall ? "pl-3 pr-3" : null)
                  }
                >
                  <p
                    className="align-item-center justify-content-center d-flex text-lmedium  font-weight-semibold"
                    style={{ textAlign: "center" }}
                  >
                    Deocamdata nu exista proiecte.
                  </p>

                  <div className="align-item-center justify-content-center d-flex ">
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        setModalOpen(!modalOpen);
                      }}
                      style={{ maxWidth: "300px", width: "300px" }}
                    >
                      Adauga un proiect nou!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Row>
      )}

      <ProjectModal
        modalOpen={modalOpen}
        toggleModal={() => {
          setModalOpen(!modalOpen);
        }}
        handleRefresh={handleRefresh}
        clients={clients}
        projectState={projectState}
      />
    </Container>
  );
};
export default Projects;