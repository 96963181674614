import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Tooltip,
} from "reactstrap";
import ProjectModal from "../Modals/ProjectModal";

let colorMap = {
  1: "#ffffff",
  2: "#0099ff",
  3: "#cc00ff",
  4: "#33cc33",
  5: "#787878",
  6: "#ff9900",
  7: "#40E0D0",
  8: "#ff0000",
  9: "#ccccff",
};

const ProjectCard = ({ project, handleRefresh, clients, projectState }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [tooltipOpenTask, setTooltipOpenTask] = useState(false);
  const [tooltipOpenEdit, setTooltipOpenEdit] = useState(false);
  const [tooltipOpenClocking, setTooltipOpenClocking] = useState(false);
  const [tooltipOpenNecessities, setTooltipOpenNecessities] = useState(false);

  const truncString = (string) => {
    if (string && string.length >= 150) {
      return string.slice(0, 150) + "...";
    }
    return string || "";
  };

  const toggleTask = () => {
    setTooltipOpenTask(!tooltipOpenTask);
  };
  const toggleEdit = () => setTooltipOpenEdit(!tooltipOpenEdit);
  const toggleClocking = () => setTooltipOpenClocking(!tooltipOpenClocking);
  const toggleNecessities = () =>
    setTooltipOpenNecessities(!tooltipOpenNecessities);

  const openEditModal = (project) => {
    setModalOpen(!modalOpen);
    setEditValues(project);
  };
  return (
    <>
      <ProjectModal
        modalOpen={modalOpen}
        toggleModal={() => {
          setModalOpen(!modalOpen);
        }}
        clients={clients}
        projectState={projectState}
        handleRefresh={handleRefresh}
        editDefaultValues={editValues}
        isEdit={true}
      />
      <Col
        className="order-xl-1 mt-4 flex-grow"
        xl="3" /* 
        style={{ minHeight: "300px" }} */
      >
        <Card className="shadow h-100">
          <CardHeader
            style={{ backgroundColor: `${colorMap[project.projectStateId]}` }}
          >
            <Row className="align-items-center">
              <Col xs="6">
                <h3
                  className="mb-0"
                  style={{
                    color:
                      colorMap[project.projectStateId] === "#ffffff"
                        ? "black"
                        : "white",
                  }}
                >
                  {project.name}
                </h3>
              </Col>
              <Col className="text-right" xs="6">
                <Tooltip
                  placement="bottom"
                  isOpen={tooltipOpenTask}
                  target={`tooltip_tasks_${project.id}`}
                  toggle={toggleTask}
                >
                  Taskuri
                </Tooltip>
                <Link
                  id={`tooltip_tasks_${project.id}`}
                  to={"/admin/projects/" + project.id + "/tasks"}
                  style={{
                    color:
                      colorMap[project.projectStateId] === "#ffffff"
                        ? "black"
                        : "white",
                  }}
                  className="mr-2"
                >
                  <i className=" fas fa-calendar-alt"></i>
                </Link>
                <Tooltip
                  placement="bottom"
                  isOpen={tooltipOpenNecessities}
                  target={`tooltip_necessities_${project.id}`}
                  toggle={toggleNecessities}
                >
                  Necesități
                </Tooltip>
                <Link
                  id={`tooltip_necessities_${project.id}`}
                  to={"/admin/project/necessities/" + project.id}
                  style={{
                    color:
                      colorMap[project.projectStateId] === "#ffffff"
                        ? "black"
                        : "white",
                  }}
                  className="mr-2"
                >
                  <i
                    id={`tooltip_necessities_${project.id}`}
                    className="fas fa-th-list"
                  ></i>
                </Link>
                <Tooltip
                  placement="bottom"
                  isOpen={tooltipOpenClocking}
                  target={`tooltip_clocking_${project.id}`}
                  toggle={toggleClocking}
                >
                  Pontaj
                </Tooltip>
                <Link
                  id={`tooltip_clocking_${project.id}`}
                  to={"/admin/project/clockings/" + project.id}
                  style={{
                    color:
                      colorMap[project.projectStateId] === "#ffffff"
                        ? "black"
                        : "white",
                  }}
                  className="mr-2"
                >
                  <i className="fas fa-clock"></i>
                </Link>
                <Tooltip
                  placement="bottom"
                  isOpen={tooltipOpenEdit}
                  target={`tooltip_edit_${project.id}`}
                  toggle={toggleEdit}
                >
                  Editeaza proiectul
                </Tooltip>
                <i
                  id={`tooltip_edit_${project.id}`}
                  className="fas fa-edit"
                  style={{
                    cursor: "pointer",
                    color:
                      colorMap[project.projectStateId] === "#ffffff"
                        ? "black"
                        : "white",
                  }}
                  onClick={() => openEditModal(project)}
                ></i>
              </Col>
            </Row>
          </CardHeader>
          {/*   <CardBody>
            <p
              className='text-muted mb-1 text-xs font-weight-500'
              style={{ textAlign: "justify", textJustify: "inter-word" }}>
              {truncString(project.name)}
            </p>
          </CardBody> */}
          <CardFooter>
            <div className="d-flex justify-content-between">
              <p
                className=" mb-1 text-sm font-weight-600 "
                style={{ textAlign: "justify", textJustify: "inter-word" }}
              >
                Ore nefacturate:{" "}
                <span className="text-danger">{project.totalHours}</span>
              </p>
            </div>
          </CardFooter>
          <CardFooter>
            <div className="d-flex justify-content-between">
              <Badge color="" className="badge-dot mr-4">
                <i className="bg-warning" />
                {project.state}
              </Badge>
              <p
                className=" mb-1 text-sm font-weight-600 "
                style={{ textAlign: "justify", textJustify: "inter-word" }}
              >
                Sold: <span className="text-success">{project.sold}</span>
              </p>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </>
  );
};

export default ProjectCard;
