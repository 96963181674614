// src/views/examples/Clocking.js
import { apiFactory } from "api";
import classnames from "classnames";
import DatatablePagination from "components/DatatablePagination";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import Moment from "react-moment";
import DatePicker from "react-datepicker";
import { usePagination, useSortBy, useTable } from "react-table";
import { useMediaQuery } from "beautiful-react-hooks";
import { useLocation, useHistory } from 'react-router-dom';
import { checkUserPermissions } from 'utils/authHelpers';
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Row,
  Table,
} from "reactstrap";
import { useReactToPrint } from "react-to-print";

function TableDefined({
  columns,
  data,
  divided = false,
  defaultPageSize = 10,
  handleRefresh,
  getProjects,
  currentUser, // Pass currentUser here
}) {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: defaultPageSize },
    },
    useSortBy,
    usePagination
  );

  const [modal, setModal] = useState(false);
  const [invoice, setInvoice] = useState(null);

  const invoiceFunction = async () => {
    await apiFactory().data.clockings().updateInvoiceClocking(invoice);
    getProjects();
    setModal(!modal);
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <div className="modal-header">
          <h3
            className="modal-title"
            id="modal-title-default"
            style={{ fontSize: "1rem" }}
          >
            Facturare
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setModal(!modal)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p className="mb-0">Dorești sa facturezi selecția?</p>
        </div>
        <div className="modal-footer">
          <Button
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setModal(!modal)}
          >
            Nu
          </Button>
          <Button
            color="primary"
            type="button"
            className="ml-auto"
            onClick={() => {
              invoiceFunction();
            }}
          >
            Da
          </Button>
        </div>
      </Modal>
      <Table
        {...getTableProps()}
        className={`r-table table ${classnames({ "table-divided": divided })}`}
        responsive
      >
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={`th_${columnIndex}`}
                  style={{ maxWidth: "200px" }}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sorted-desc"
                        : "sorted-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                  <span />
                </th>
              ))}
              {currentUser.roleId !== 1 && <th scope="col" />}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => (
                  <td
                    key={`td_${cellIndex}`}
                    style={{ maxWidth: "200px" }}
                    {...cell.getCellProps({
                      className: cell.column.cellClass,
                    })}
                  >
                    {cell.column.id === "start" ? (
                      <Moment format="HH:mm">{cell.value}</Moment>
                    ) : cell.column.id === "stop" ? (
                      <>
                        {cell.value !== null && <Moment format="HH:mm">{cell.value}</Moment>}
                      </>
                    ) : cell.column.id === "date" ? (
                      <Moment locale="ro" format="DD MMMM, YYYY">
                        {cell.value}
                      </Moment>
                    ) : (
                      cell.value
                    )}
                  </td>
                ))}
                {currentUser.roleId !== 1 && (
                  <td key={`td_5`} style={{ maxWidth: "50px" }}>
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-outline-primary mr-1 btn-sm"
                        disabled={row.original.invoiced}
                        onClick={() => {
                          setInvoice(row.original.id);
                          setModal(!modal);
                        }}
                      >
                        <i className="fas fa-th-list mr-2" />
                        Facturează
                      </button>
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <DatatablePagination
        page={pageIndex}
        pages={pageCount}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageSizeOptions={[5, 10, 20, 30, 40, 50]}
        showPageSizeOptions={true}
        showPageJump={false}
        defaultPageSize={pageSize}
        onPageChange={(p) => gotoPage(p)}
        onPageSizeChange={(s) => setPageSize(s)}
        paginationMaxSize={5}
      />
    </>
  );
}


const ClockingTable = ({ clockings, getProjects, currentUser }) => {
  const cols = React.useMemo(() => [
    {
      Header: "Username",
      accessor: "userName",
      cellClass: "text-muted",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Proiect",
      accessor: "project",
      cellClass: "text-muted",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Task",
      accessor: "taskName",
      cellClass: "text-muted",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Start",
      accessor: "start",
      cellClass: "text-muted",
      Cell: (props) => (
        <>{<Moment format="YYYY/MM/DD">{props.value}</Moment>}</>
      ),
    },
    {
      Header: "Stop",
      accessor: "stop",
      cellClass: "text-muted",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Data",
      accessor: "date",
      cellClass: "text-muted",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Timp Lucrat",
      accessor: "timeWork",
      cellClass: "text-muted",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Echipa",
      accessor: "team",
      cellClass: "text-muted wrap",
      Cell: (props) => <>{props.value}</>,
    },
    {
      Header: "Observații",
      accessor: "comment",
      cellClass: "text-muted wrap",
      Cell: (props) => <>{props.value}</>,
    },
  ]);

  return (
    <>
      <TableDefined
        columns={cols}
        data={clockings}
        getProjects={getProjects}
        currentUser={currentUser} // Pass currentUser here
      />
    </>
  );
};

const ForPrint = ({ componentRef, clockings }) => {
  const truncString = (string) => {
    if (string && string.length >= 27) {
      return string.slice(0, 27) + "...";
    }
    return string || "";
  };
  return (
    <div className="m-4" ref={componentRef}>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Username</th>
            <th scope="col">Proiect</th>
            <th scope="col">Task</th>
            <th scope="col">Start</th>
            <th scope="col">Stop</th>
            <th scope="col">Data</th>
            <th scope="col">Timp Lucrat</th>
            <th scope="col">Echipa</th>
            <th scope="col">Observații</th>
          </tr>
        </thead>
        <tbody>
          {clockings.map((res) => {
            return (
              <tr>
                <td>{res.userName}</td>
                <td>{res.project}</td>
                <td>{res.nameTask}</td>
                <td>
                  <Moment format="HH:mm">{res.start}</Moment>
                </td>
                <td>
                  {res.stop !== null && (
                    <Moment format="HH:mm">{res.stop}</Moment>
                  )}
                </td>
                <td>
                  <Moment locale="ro" format="DD MMMM, YYYY">
                    {res.date}
                  </Moment>
                </td>
                <td>{res.timeWork}</td>
                <td>{res.team}</td>
                <td>{truncString(res.comment)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const Clocking = () => {
  const [clockings, setClockings] = useState([]);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [teams, setTeams] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [invoices, setInvoices] = useState([
    {
      id: 0,
      name: "Nefacturat",
    },
    {
      id: 1,
      name: "Facturat",
    },
  ]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenUser, setDropdownOpenUser] = useState(false);
  const [dropdownOpenTeam, setDropdownOpenTeam] = useState(false);
  const [dropdownOpenInvoice, setDropdownOpenInvoice] = useState(false);
  const [selectedProject, setSelectedProject] = useState({
    id: 0,
    name: "Selectează Proiect",
  });
  const [selectedInvoice, setSelectedInvoice] = useState({
    id: -1,
    name: "Nefacturat/Facturat",
  });
  const [selectedUser, setSelectedUser] = useState({
    id: 0,
    userName: "Selectează Utilizator",
  });
  const [selectedTeam, setSelectedTeam] = useState({
    id: 0,
    name: "Selectează Echipa",
  });
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const isSmall = useMediaQuery("(max-width: 1110px)");

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleUser = () => setDropdownOpenUser((prevState) => !prevState);
  const toggleTeam = () => setDropdownOpenTeam((prevState) => !prevState);
  const toggleInvoice = () => setDropdownOpenInvoice((prevState) => !prevState);

  const fetchUsers = async () => {
    const newUsers = await apiFactory().data.users().getUsers();
    setUsers(newUsers);
  };

  const fetchProjects = async () => {
    const projectsResponse = await apiFactory().data.projects().getProjects();
    setProjects(projectsResponse);
  };

  const fetchTeams = async () => {
    const teamsResponse = await apiFactory().data.users().getTeams();
    setTeams(teamsResponse);
  };

  const getProjects = async ({ roleId, userId } = {}) => {
  
    if (roleId === undefined || userId === undefined) {
      console.error('roleId or userId is undefined');
      return;
    }
  
    const newClocking = await apiFactory()
      .data.clockings()
      .getClockings(
        selectedProject.id,
        roleId === 1 ? userId : selectedUser.id,
        selectedTeam.id,
        selectedInvoice.id,
        startDate !== null
          ? moment(startDate).format("YYYY-MM-DD HH:mm:ss")
          : null,
        endDate !== null ? moment(endDate).format("YYYY-MM-DD HH:mm:ss") : null
      );
    
    const change = newClocking.map((element) => {
      const timeWorked = moment.duration(
        moment(element.stop).diff(element.start)
      );
      return {
        ...element,
        date: element.start,
        timeWork:
          element.stop !== null &&
          `${timeWorked.days()}d ${timeWorked.hours()}h ${timeWorked.minutes()}m`,
        taskName: element.nameTask,
      };
    });
  
    setClockings(change);
  };

  const [isAuthorized, setIsAuthorized] = useState(true);
  const location = useLocation();
  const history = useHistory();

  const [currentUser, setCurrentUser] = useState({ roleId: null, userId: null });

  useEffect(() => {
    const authorize = async () => {
      const authorized = await checkUserPermissions(location.pathname, history);
      if (authorized) {
        const { roleId, userId } = authorized;
        setCurrentUser({ roleId, userId });
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    };
    authorize();
  }, [location.pathname, history]);

  useEffect(() => {
    if (isAuthorized && currentUser.roleId !== null && currentUser.userId !== null) {
      getProjects(currentUser);
      fetchUsers();
      fetchProjects();
      fetchTeams();
    }
  }, [
    selectedProject,
    selectedUser,
    selectedTeam,
    selectedInvoice,
    startDate,
    endDate,
    isAuthorized,
    currentUser
  ]);

  return (
    <Container fluid>
      <Row>
        <Col xxs="12" className="mb-4 mt-4">
          <Card className="mb-4 shadow">
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Pontaj</h3>
                </Col>
              </Row>
            </CardHeader>
            <div className="mb-3 d-flex align-items-center flex-wrap">
              <Dropdown isOpen={dropdownOpen} toggle={toggle} className="m-2">
                <DropdownToggle caret>{selectedProject.name}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    key={0}
                    onClick={() => {
                      setSelectedProject({
                        id: 0,
                        name: "Selecteaza Proiect",
                      });
                    }}
                  >
                    Selecteaza Proiect
                  </DropdownItem>
                  {projects.map((x) => (
                    <DropdownItem
                      key={x.id}
                      onClick={() => {
                        setSelectedProject(x);
                      }}
                    >
                      {x.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>{" "}
              {currentUser.roleId !== 1 && (
                <Dropdown
                  isOpen={dropdownOpenUser}
                  toggle={toggleUser}
                  className="m-2"
                >
                  <DropdownToggle caret>{selectedUser.userName}</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      key={0}
                      onClick={() => {
                        setSelectedUser({
                          id: 0,
                          userName: "Selecteaza Utilizator",
                        });
                      }}
                    >
                      Selecteaza Utilizator
                    </DropdownItem>
                    {users.map((x) => (
                      <DropdownItem
                        key={x.id}
                        onClick={() => {
                          setSelectedUser(x);
                        }}
                      >
                        {x.userName}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              )}
              <Dropdown
                isOpen={dropdownOpenTeam}
                toggle={toggleTeam}
                className="m-2"
              >
                <DropdownToggle caret>{selectedTeam.name}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    key={0}
                    onClick={() => {
                      setSelectedTeam({
                        id: 0,
                        name: "Selecteaza Echipa",
                      });
                    }}
                  >
                    Selecteaza Echipa
                  </DropdownItem>
                  {teams.map((x) => (
                    <DropdownItem
                      key={x.id}
                      onClick={() => {
                        setSelectedTeam(x);
                      }}
                    >
                      {x.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>{" "}
              <div className="m-2">
                <DatePicker
                  placeholderText="Dată început"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  className="form-control-alternative form-control d-flex"
                />
              </div>
              <div className="m-2">
                <DatePicker
                  placeholderText="Dată final"
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  className="form-control-alternative form-control d-flex"
                />
              </div>
              {currentUser.roleId !== 1 && (
                <Dropdown
                  isOpen={dropdownOpenInvoice}
                  toggle={toggleInvoice}
                  className="m-2"
                >
                  <DropdownToggle caret>{selectedInvoice.name}</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      key={0}
                      onClick={() => {
                        setSelectedInvoice({
                          id: -1,
                          name: "Nefacturat/Facturat",
                        });
                      }}
                    >
                      Nefacturat/Facturat
                    </DropdownItem>
                    {invoices.map((x) => (
                      <DropdownItem
                        key={x.id}
                        onClick={() => {
                          setSelectedInvoice(x);
                        }}
                      >
                        {x.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              )}
              <div className={`flex-grow-${isSmall ? 0 : 1} m-2`}>
                <Button
                  size="md"
                  color="warning"
                  onClick={() => window.location.reload()}
                >
                  RESETARE
                </Button>
              </div>
              <div
                className={`flex-grow-${isSmall ? 0 : 1
                  } d-flex justify-content-end m-2`}
              >
                <Button size="md" color="success" onClick={handlePrint}>
                  PDF EXPORT
                </Button>
              </div>
            </div>
  
            <ClockingTable clockings={clockings} getProjects={() => getProjects(currentUser)} currentUser={currentUser} />

          </Card>
          <div style={{ display: "none" }}>
            <ForPrint componentRef={componentRef} clockings={clockings} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Clocking;
