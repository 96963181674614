import React, { useState, useEffect, useMemo } from "react";
import * as yup from "yup";
import "yup-phone";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";

import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  Card,
} from "reactstrap";

import { apiFactory } from "api";

const validationSchema = yup.object().shape({
  name: yup.string().required("Camp obligatoriu"),/* 
  comment: yup.string(), */
  // sold: yup.number().typeError("Trebuie sa fie un numar."),
});

const ProjectModal = ({
  modalOpen,
  toggleModal,
  handleRefresh,
  editDefaultValues,
  isEdit,
  clients,
  projectState,
}) => {
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    defaultValues: useMemo(() => editDefaultValues, [editDefaultValues]),
  });

  /*  const [clients, setClients] = useState([]);
  const [projectState, setProjectState] = useState([]); */

  const onSubmit = async (data) => {
    const createProject = async () => {
      await apiFactory().data.projects().createProject({
        name: data.name,/* 
        comment: data.comment, */
        // sold: data.sold,
        idClient: data.idClient,
        projectStateId: data.projectStateId,
      });
      handleRefresh();
    };
    const updateProject = async () => {
      await apiFactory().data.projects().updateProject(
        {
          name: data.name,/* 
          comment: data.comment, */
          sold: data.sold,
          idClient: data.idClient,
          projectStateId: data.projectStateId,
        },
        editDefaultValues.id
      );
      handleRefresh();
    };

    if (_.isEmpty(editDefaultValues)) {
      createProject();
    } else {
      updateProject();
    }

    toggleModal();
  };

  useEffect(() => {
    setTimeout(() => {
      if (!_.isEmpty(editDefaultValues)) {
        for (var key of Object.keys(editDefaultValues)) {
          setValue(key, editDefaultValues[key]);
        }
      }
    });
  }, [editDefaultValues, modalOpen]);

  /* const fetchNewClients = async () => {
    const newclients = await apiFactory().data.clients().getClients();
    setClients(newclients);
  };
  const fetchProjectState = async () => {
    const newProjectsStates = await apiFactory()
      .data.projects()
      .getStateProjects();
    setProjectState(newProjectsStates);
  }; */

  /* useEffect(() => {
    fetchNewClients();
    fetchProjectState();
  }, []); */

  return (
    <Modal
      isOpen={modalOpen}
      toggle={toggleModal}
      wrapClassName='modal-right bg-secondary '
      backdrop='static'>
      <Card className='bg-secondary shadow border-0'>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggleModal}>
            {isEdit ? "Editare proiect" : "Adauga proiect nou"}
          </ModalHeader>
          <ModalBody>
            <Label>Denumire proiect</Label>
            <Input
              innerRef={register}
              type='text'
              name='name'
              maxLength={255}
              placeholder=''
              className='form-control-alternative'
            />
            <p className='text-danger font-weight-normal text-xs mt-2'>
              {errors.name?.message}
            </p>
{/* 
            <Label>Descriere</Label>
            <Input
              innerRef={register}
              type='textarea'
              name='comment'
              placeholder=''
              className='form-control-alternative'
            />
            <p className='text-danger font-weight-normal text-xs mt-2'>
              {errors.comment?.message}
            </p> */}

            {/* <Label>Sold Proiect</Label>
            <Input
              innerRef={register}
              type='text'
              name='sold'
              placeholder=''
              className='form-control-alternative'
            />
            <p className='text-danger font-weight-normal text-xs mt-2'>
              {errors.sold?.message}
            </p> */}
            <Label>Client</Label>
            <Input
              innerRef={register}
              type='select'
              name='idClient'
              className='form-control-alternative'>
              {clients &&
                clients.map((element) => {
                  return (
                    <option value={element.id} key={element.id}>
                      {element.name}
                    </option>
                  );
                })}
            </Input>
            <p className='text-danger font-weight-normal text-xs mt-2'></p>
            <Label>Stare Proiect</Label>
            <Input
              innerRef={register}
              type='select'
              name='projectStateId'
              className='form-control-alternative'>
              {projectState &&
                projectState.map((element) => {
                  return (
                    <option value={element.id} key={element.id}>
                      {element.name}
                    </option>
                  );
                })}
            </Input>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={toggleModal}>
              Renunță
            </Button>
            <Button color='primary' type='submit'>
              Salvează
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Card>
    </Modal>
  );
};

export default ProjectModal;
