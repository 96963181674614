import {apiFactory} from 'api';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import Moment from 'react-moment';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {useInterval} from '../../utils/useInterval';
import { useLocation, useHistory } from 'react-router-dom';
import { checkUserPermissions } from 'utils/authHelpers';

const ClockingDash = () => {
  const [usersWithTasks, setUsersWithTasks] = useState([]);
  const [clockings, setClockings] = useState([]);

  const fetchTasks = async () => {
    const fetchedUsersWithTasks = await apiFactory()
      .data.users()
      .getUsersTasks();
    if (
      fetchedUsersWithTasks &&
      !_.isEqual(fetchedUsersWithTasks, usersWithTasks)
    )
      setUsersWithTasks(fetchedUsersWithTasks);
  };

  const fetchClocks = async () => {
    const fetchedClockings = await apiFactory()
      .data.clockings()
      .getUsersStatisticsClockingTVBoard();
    if (fetchedClockings && !_.isEqual(fetchedClockings, clockings))
      setClockings(fetchedClockings);
  };
  const [isAuthorized, setIsAuthorized] = useState(true);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const authorize = async () => {
      const authorized = await checkUserPermissions(location.pathname, history);
      if (authorized) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    };
    authorize();
  }, [location.pathname, history]);

  useEffect(() => {
    if (isAuthorized) {
      fetchTasks();
      fetchClocks();
    }
  }, [isAuthorized]);


  useInterval(async () => {
    fetchTasks();
    fetchClocks();
  }, 30000);

  const getDayOfTheYear = (date) => {
    let now;
    if (date) now = date;
    else now = new Date();
    const start = new Date(now.getFullYear(), 0, 0);
    const diff = now - start;
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
  };

  const printTeamStatus = (teamClockings, logged) => {
    if (logged) {
      if (teamClockings.users.some((user) => !user.stop && user.start)) {
        const loggedUsers = teamClockings.users
          .filter((user) => !user.stop && user.start)
          .map((user, key) => {
            return (
              <div key={key} className="w-100 d-flex b-bottom-subtile">
                <div className="ml-2">
                  {' '}
                  <p
                    className="font-weight-bold text-black m-0"
                    style={{fontSize: 15}}
                  >
                    {user.firstName + ' ' + user.lastName}{' '}
                  </p>
                </div>

                <p
                  className="d-inline-block font-weight-bold ml-auto mb-0 mt-0"
                  style={{fontSize: 15}}
                >
                  {user.start &&
                    (new Date(user.start).getYear() !== new Date().getYear() ||
                    getDayOfTheYear(new Date(user.start)) !==
                      getDayOfTheYear() ? (
                      <Moment
                        locale="ro"
                        format="HH:mm (DD MMM) "
                        style={{color: 'red'}}
                      >
                        {user.start}
                      </Moment>
                    ) : (
                      <Moment locale="ro" format="HH:mm (DD MMM) ">
                        {user.start}
                      </Moment>
                    ))}
                </p>
              </div>
            );
          });
        return (
          <div className="mb-2">
            <h6 className="text-uppercase mb-1 card-title cd-card-title-font-size">
              Echipa: {teamClockings.name}
            </h6>
            {loggedUsers}
          </div>
        );
      }
    } else {
      if (
        teamClockings.users.some(
          (user) => user.stop || (!user.stop && !user.start),
        )
      ) {
        const loggedUsers = teamClockings.users
          .filter((user) => user.stop || (!user.stop && !user.start))
          .map((user, key) => {
            return (
              <div key={key} className="w-100 d-flex b-bottom-subtile">
                <div className="ml-2">
                  {' '}
                  <p
                    className="font-weight-bold text-black m-0"
                    style={{fontSize: 15}}
                  >
                    {user.firstName + ' ' + user.lastName}{' '}
                  </p>
                </div>

                <p
                  className="d-inline-block font-weight-bold ml-auto mb-0 mt-0"
                  style={{fontSize: 15}}
                >
                  {user.stop && (
                    <Moment locale="ro" format="HH:mm (DD MMM)">
                      {user.stop}
                    </Moment>
                  )}
                </p>
              </div>
            );
          });
        return (
          <div className="mb-2">
            <h6 className="text-uppercase mb-1 card-title">
              Echipa: {teamClockings.name}
            </h6>
            {loggedUsers}
          </div>
        );
      }
    }
  };

  const getTaskInitials = (name) => {
    const splitName = name.split(' ');
    let initials = '';
    initials = splitName[0] ? splitName[0][0].toUpperCase() : '';
    initials = splitName[1]
      ? initials + splitName[1][0].toUpperCase()
      : initials;
    return initials;
  };

  if (!isAuthorized) {
    return (
      <Container fluid>
        <Row>
          <Col className="text-center">
            <h1>Not Authorized</h1>
            <p>You do not have permission to view this page.</p>
          </Col>
        </Row>
      </Container>
    );
  }
  
  return (
    <Container style={{backgroundColor: '#E4ECEE'}} className="" fluid>
      <div className="cd-main-container">
        {' '}
        <Row>
          <Col
            className="pt-1 d-flex flex-column min-vh-100 align-items-start"
            xs={2}
            style={{paddingRight: '5px'}}
          >
            <Card className="cd-card shadow">
              <CardHeader className="cd-task-bg-green p-0">
                <h3 className="mb-0 ml-2 font-weight-bold text-white">
                  Useri Logați
                </h3>
              </CardHeader>
              <div className="cd-task-scrollbar">
                <CardBody className="pt-2 pl-3 pr-3">
                  {clockings &&
                    _.isArray(clockings) &&
                    clockings.map((teamClockings) => {
                      return printTeamStatus(teamClockings, true);
                    })}
                </CardBody>
              </div>
            </Card>
            <Card className="cd-card shadow mt-2">
              {' '}
              <CardHeader className="cd-task-bg-red p-0">
                <h3 className="mb-0 ml-2 font-weight-bold text-white">
                  Useri Delogați
                </h3>
              </CardHeader>
              <div className="cd-task-scrollbar">
                <CardBody className="pt-2 pl-3 pr-3">
                  {clockings &&
                    clockings.map((teamClockings) => {
                      return printTeamStatus(teamClockings, false);
                    })}
                </CardBody>
              </div>
            </Card>
          </Col>
          <Col xs={10}>
            {/* <Col className="pl-4" xs={10}> */}
            {/* <ReactBootstrapCarousel
            animation={false}
            slideshowSpeed={9000}
            defaultActiveIndex={0}
            leftIcon={
              <h1
                style={{
                  userSelect: 'none',
                  fontSize: '8rem',
                  marginRight: '11.5vw',
                }}
              >
                &lsaquo;
              </h1>
            }
            rightIcon={
              <h1
                style={{
                  userSelect: 'none',
                  fontSize: '8rem',
                  marginLeft: '11.5vw',
                }}
              >
                &rsaquo;
              </h1>
            }
            version={4}
          > */}
            {usersWithTasks &&
              _.chunk(
                usersWithTasks
                  .filter((userWithTask) => {
                    return (
                      userWithTask.tasks.length > 0 &&
                      userWithTask.tasks.some((task) => task.stateId < 4)
                    );
                  })
                  .sort(function (a, b) {
                    return a.tasks.length - b.tasks.length;
                  }),
                4,
              ).map((chunkedUsersWithTasks) => {
                return (
                  <Row className="pt-1">
                    {chunkedUsersWithTasks.map((user) => {
                      return (
                        <Col
                          xs={3}
                          style={{paddingRight: '5px', paddingLeft: '5px'}}
                        >
                          <Card className="shadow" style={{height: '100%'}}>
                            <CardHeader className="cd-task-card-header m-0 p-1">
                              <Row className="p-0 m-0">
                                <Col className="d-flex justify-content-between">
                                  <span
                                    className="h2 font-weight-bold text-white p-0 m-0"
                                    style={{lineHeight: '1rem'}}
                                  >
                                    {user.firstName} {user.lastName}
                                  </span>
                                  <p className="h5 text-white text-right p-0 m-0">
                                    Echipa: {user.team}
                                  </p>
                                </Col>
                              </Row>
                            </CardHeader>
                            <Col className="pl-2 pr-2 mt-0">
                              {user.tasks.map((task) => {
                                if (task.stateId < 4)
                                  return (
                                    <div className="b-bottom-subtile pb-1 pt-2">
                                      <div className="d-flex">
                                        <div
                                          style={{fontSize: '0.9rem'}}
                                          className={`icon-size-ms icon icon-shape text-white rounded-circle shadow ${
                                            Date.parse(
                                              new Date(task.deadlineDate),
                                            ) -
                                              Date.parse(new Date()) <
                                            0
                                              ? 'cd-task-bg-red'
                                              : task.stateId > 1
                                              ? 'cd-task-bg-green'
                                              : 'cd-task-bg-dark'
                                          }`}
                                        >
                                          {getTaskInitials(task.projectName)}
                                        </div>
                                        {task.deadlineDate && (
                                          <span className="text-black pb-0 ml-1 border-right pr-2">
                                            <Moment format="DD.MM">
                                              {task.deadlineDate}
                                            </Moment>
                                          </span>
                                        )}

                                        <div className="pl-2 pr-2">
                                          <p className="cd-task-text mb-0">
                                            {task.name}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                              })}
                            </Col>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                );
              })}
            {/* </ReactBootstrapCarousel> */}
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default ClockingDash;
