import { useMediaQuery } from "beautiful-react-hooks";
// import AddressModal from "components/account/createAddressModal";
// import { Colxx } from "components/common/CustomBootstrap";
// import ListPageHeading from "containers/pages/ListPageHeading";
import React, { useEffect, useState } from "react";
import { useParams,useLocation, useHistory } from "react-router-dom";
import { checkUserPermissions } from 'utils/authHelpers';
import { Card, CardHeader, Col, Row, CardBody, Container } from "reactstrap";
import TaskCard from "components/Cards/TaskCard.js";
import TaskModal from "components/Modals/TaskModal.js";
import { apiFactory } from "api";
import ReactLoading from "react-loading";

const EditTasks = () => {
  const { id } = useParams();
  const isSmall = useMediaQuery("(max-width: 57rem)");
  const [project, setProject] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [tasks, setTasks] = useState(null);
  const [taskStates, setTaskStates] = useState([]);

  const [isAuthorized, setIsAuthorized] = useState(true);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const authorize = async () => {
      const authorized = await checkUserPermissions(location.pathname, history);
      if (authorized) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    };
    authorize();
  }, [location.pathname, history]);

  useEffect(() => {
    if (isAuthorized) {
      getStates();
      fetchNewTasks();
      fetchCurrentProject();
    }
  }, [isAuthorized]);

  const fetchCurrentProject = async () => {
    const newProject = await apiFactory().data.projects().getProjectById(id);
    setProject(newProject[0]);
  };

  const fetchNewTasks = async () => {
    const newTasks = await apiFactory().data.projects().getProjectTasks(id);
    setTasks(newTasks);
  };

  const handleRefresh = () => {
    fetchNewTasks();
  };

  const getStates = async () => {
    const states = await apiFactory().data.tasks().getTaskStates();
    setTaskStates(states);
  };

  if (!isAuthorized) {
    return (
      <Container fluid>
        <Row>
          <Col className="text-center">
            <h1>Not Authorized</h1>
            <p>You do not have permission to view this page.</p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      {!tasks || !project ? (
        <div
          className="d-flex flex-direction-row justify-content-center align-items-center align-content-center"
          style={{ height: "90vh" }}>
          <ReactLoading
            type={"spinningBubbles"}
            color={"#404E68"}
            height={"70px"}
            width={"70px"}
          />
        </div>
      ) : (
        <Row>
          {tasks && tasks.length > 0 ? (
            <Col xxs="12" className="mb-4 m-4">
              <Card className="shadow">
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h2 className="mb-0">
                      Task-uri pentru proiectul{" "}
                      <span style={{ color: "#00AABC" }}> {project.name}</span>
                    </h2>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => setModalOpen(true)}>
                      Adauga task nou
                    </button>
                  </div>
                </CardBody>
              </Card>
              <Row className="d-flex align-items-stretch">
                {tasks.map((task) => (
                  <TaskCard
                    task={task}
                    taskStates={taskStates}
                    projectName={project.name}
                    handleRefresh={handleRefresh}
                    projectId={id}
                    key={task.id}
                  />
                ))}
              </Row>
            </Col>
          ) : (
            <div
              className="flex-column d-flex w-100 justify-content-center align-item-center"
              style={{ height: "80vh" }}>
              <div className="flex-row d-flex justify-content-center align-item-center">
                <div
                  className={
                    "flex-column d-flex justify-content-center align-item-center " +
                    (isSmall ? "pl-3 pr-3" : null)
                  }>
                  <p
                    className="align-item-center justify-content-center d-flex text-lmedium  font-weight-semibold"
                    style={{ textAlign: "center" }}>
                    Deocamdata nu exista taskuri pentru proiectul:{" "}
                    <span style={{ color: "#00AABC", fontWeight: "bold" }}>
                      {" "}
                      {project.name}
                    </span>
                    .
                  </p>

                  <div className="align-item-center justify-content-center d-flex ">
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        setModalOpen(!modalOpen);
                      }}
                      style={{ maxWidth: "300px", width: "300px" }}>
                      Adauga un task nou!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <TaskModal
            projectId={id}
            projectName={project.name}
            modalOpen={modalOpen}
            toggleModal={() => {
              setModalOpen(!modalOpen);
            }}
            handleRefresh={handleRefresh}
          />
        </Row>
      )}
    </Container>
  );
};
export default EditTasks;
