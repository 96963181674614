import React, { useState, useEffect } from "react";
import * as yup from "yup";
import "yup-phone";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { checkUserPermissions } from 'utils/authHelpers';
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  Card,
  Col,
  Row,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
} from "reactstrap";
import ReactLoading from "react-loading";
import { apiFactory } from "api";
import UsersTable from "components/Tables/UsersTable.js";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import '../../assets/css/style.css'

const localizer = momentLocalizer(moment);

const validationSchema = yup.object().shape({
  name: yup.string().required("Camp obligatoriu"),/* 
  comment: yup.string().required("Camp obligatoriu"), */
  sold: yup.number().required("Camp obligatoriu"),
});

export default function EditProjects() {
  const { id } = useParams();
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
  });

  const [isAuthorized, setIsAuthorized] = useState(true);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const authorize = async () => {
      const authorized = await checkUserPermissions(location.pathname, history);
      if (authorized) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    };
    authorize();
  }, [location.pathname, history]);

  useEffect(() => {
    if (isAuthorized) {
      fetchNewClients();
      fetchProjectState();
      fetchNewproject();
      fetchNewUsers();
      fetchNewTasks();
    }
  }, [isAuthorized]);

  const [loadingParameters, setLoadingParameters] = useState({
    project: false,
    clients: false,
    projectState: false,
    users: false,
  });

  const [project, setProject] = useState(null);
  const [clients, setClients] = useState(null);
  const [projectState, setProjectState] = useState(null);
  const [users, setUsers] = useState(null);
  const [tasks, setTasks] = useState(null);

  const onSubmit = async (data) => {
    const createProject = async () => {
      await apiFactory().data.projects().createProject({
        name: data.name,/* 
        comment: data.comment, */
        sold: data.sold,
        idClient: data.idClient,
        projectStateId: data.projectStateId,
      });
    };
    createProject();
  };

  const fetchNewClients = async () => {
    const newclients = await apiFactory().data.clients().getClients();
    setClients(newclients);
  };
  const fetchProjectState = async () => {
    const newProjectsStates = await apiFactory()
      .data.projects()
      .getStateProjects();
    setProjectState(newProjectsStates);
  };

  const fetchNewproject = async () => {
    const newProject = await apiFactory().data.projects().getProjectById(id);
    setProject(newProject[0]);
  };

  const fetchNewUsers = async () => {
    const newUsers = await apiFactory().data.projects().getProjectUsers(id);
    // if (!newUsers.message)
    setUsers(newUsers);
  };
  const fetchNewTasks = async () => {
    const newTasks = await apiFactory().data.projects().getProjectTasks(id);
    const newEvents = newTasks.map((task) => {
      return {
        start: moment(task.startDate).toDate(),
        end: moment(task.deadlineDate).toDate(),
        title: task.name /* + " - " + task.comment */,
      };
    });
    setTasks(newEvents);
  };

  const handleRefresh = () => {
    fetchNewproject();
  };

  useEffect(() => {
    setTimeout(() => {
      if (!_.isEmpty(project)) {
        for (var key of Object.keys(project)) {
          setValue(key, project[key]);
        }
      }
    });
  }, [project]);

  useEffect(() => {
  }, [loadingParameters]);

  if (!isAuthorized) {
    return (
      <Container fluid>
        <Row>
          <Col className="text-center">
            <h1>Not Authorized</h1>
            <p>You do not have permission to view this page.</p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      {!clients || !project || !projectState || !users || !tasks ? (
        <div
          className='d-flex flex-direction-row justify-content-center align-items-center align-content-center'
          style={{ height: "80vh" }}>
          <ReactLoading
            type={"spinningBubbles"}
            color={"#404E68"}
            height={"70px"}
            width={"70px"}
          />
        </div>
      ) : (
        <>
          <Row className='d-flex align-items-stretch align-content-stretch'>
            <Col xxs='12' xl='6' className='mb-4 mt-4'>
              <Card className='shadow  '>
                <CardBody>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Label>Nume</Label>
                    <Input
                      innerRef={register}
                      type='text'
                      name='name'
                      maxLength={255}
                      placeholder=''
                    />
                    <p className='text-danger font-weight-normal text-xs mt-2'>
                      {errors.name?.message}
                    </p>
                   {/*  <Label>Descriere proiect</Label>
                    <Input
                      innerRef={register}
                      type='textarea'
                      name='comment'
                      rows='4'
                      placeholder=''
                    />
                    <p className='text-danger font-weight-normal text-xs mt-2'>
                      {errors.comment?.message}
                    </p> */}
                    <Label>Sold Proiect</Label>
                    <Input
                      innerRef={register}
                      type='text'
                      name='sold'
                      placeholder=''
                    />
                    <p className='text-danger font-weight-normal text-xs mt-2'>
                      {errors.sold?.message}
                    </p>
                    <Label>Client</Label>
                    <Input innerRef={register} type='select' name='idClient'>
                      {clients &&
                        clients.map((element) => {
                          return (
                            <option value={element.id} key={element.id}>
                              {element.name}
                            </option>
                          );
                        })}
                    </Input>
                    <p className='text-danger font-weight-normal text-xs mt-2'></p>
                    <Label>Stare Proiect</Label>
                    <Input
                      innerRef={register}
                      type='select'
                      name='projectStateId'>
                      {projectState &&
                        projectState.map((element) => {
                          return (
                            <option value={element.id} key={element.id}>
                              {element.name}
                            </option>
                          );
                        })}
                    </Input>
                    <CardFooter>
                      {/* <Button color='secondary' outline onClick={toggleModal}>
                  Renunță
                </Button> */}
                      <Button color='primary' type='submit'>
                        Salvează
                      </Button>{" "}
                    </CardFooter>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col xxs='12' xl='6' className='mb-4 mt-4 flex-grow'>
              <Card className='mb-4 shadow h-100 '>
                <CardBody className=' h-100'>
                  <UsersTable users={users} handleRefresh={handleRefresh} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xxs='12' xl='12' className='mb-4 mt-4 flex-grow'>
              <Card className='mb-4 shadow h-100 '>
                <CardBody className=' h-100'>
                  <Calendar
                    localizer={localizer}
                    defaultDate={new Date()}
                    defaultView='agenda'
                    events={tasks}
                    startAccessor='start'
                    endAccessor='end'
                    style={{ fontSize: 11, height: "70vh" }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}
