import { apiFactory } from "api";
import { useMediaQuery } from "beautiful-react-hooks";
import ClientModal from "components/Modals/ClientModal.js";
import ClientsTable from "components/Tables/ClientsTable.js";
import React, { useEffect, useState } from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { useLocation, useHistory } from 'react-router-dom';
import { checkUserPermissions } from 'utils/authHelpers';

const Clients = () => {
  const isSmall = useMediaQuery("(max-width: 57rem)");
  const [modalOpen, setModalOpen] = useState(false);
  const [clients, setClients] = useState([]);

  const fetchNewClients = async () => {
    const newclients = await apiFactory().data.clients().getClients();
    setClients(newclients);
  };

  const handleRefresh = () => {
    fetchNewClients();
  };
  const [isAuthorized, setIsAuthorized] = useState(true);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const authorize = async () => {
      const authorized = await checkUserPermissions(location.pathname, history);
      if (authorized) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    };
    authorize();
  }, [location.pathname, history]);

  useEffect(() => {
    if (isAuthorized) {
      fetchNewClients();
    }
  }, [isAuthorized]);

  //

  return (
    <Container fluid>
      <Row>
        {clients && clients.length > 0 ? (
          <Col xxs='12' className='mb-4 mt-4'>
            <Card className='mb-4 shadow'>
              <CardHeader className='border-0'>
                <div className='d-flex justify-content-between'>
                  <h2 className='mb-0'>Clienti</h2>
                  <button
                    type='button'
                    className='btn btn-primary btn-sm'
                    onClick={() => setModalOpen(true)}>
                    Adauga un client nou
                  </button>
                </div>
              </CardHeader>
              <ClientsTable clients={clients} handleRefresh={handleRefresh} />
            </Card>
          </Col>
        ) : (
          <div
            className='flex-column d-flex w-100 justify-content-center align-item-center'
            style={{ height: "80vh" }}>
            <div className='flex-row d-flex justify-content-center align-item-center'>
              <div
                className={
                  "flex-column d-flex justify-content-center align-item-center " +
                  (isSmall ? "pl-3 pr-3" : null)
                }>
                <p
                  className='align-item-center justify-content-center d-flex text-lmedium  font-weight-semibold'
                  style={{ textAlign: "center" }}>
                  Deocamdata nu ai nici un client adaugat.
                </p>

                <div className='align-item-center justify-content-center d-flex '>
                  <button
                    className='btn btn-primary mt-3'
                    onClick={() => {
                      setModalOpen(!modalOpen);
                    }}
                    style={{ maxWidth: "300px", width: "300px" }}>
                    Adauga mai multi clienti!
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Row>
      <ClientModal
        modalOpen={modalOpen}
        toggleModal={() => {
          setModalOpen(!modalOpen);
        }}
        handleRefresh={handleRefresh}
      />
    </Container>
  );
};
export default Clients;
