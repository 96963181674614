import React, { useState, useEffect, useImperativeHandle } from "react";
import { apiFactory } from "api";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import TaskModal from "../components/Modals/TaskModal";
import { useMediaQuery } from "beautiful-react-hooks";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Header from "components/Headers/Header.js";
import "../assets/css/style.css";

const localizer = momentLocalizer(moment);

const Index = (props) => {
  const isSmall = useMediaQuery("(max-width: 65rem)");
  const [activeNav, setActiveNav] = useState(1);
  const [myUserInfo, setMyUserInfo] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectedProject, setSelectedProject] = useState({
    id: 0,
    name: "Selectează Proiect",
  });
  const [selectedUser, setSelectedUser] = useState({
    id: 0,
    userName: "Selectează Utilizator",
  });
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenUser, setDropdownOpenUser] = useState(false);
  const [events, setEvents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleUser = () => setDropdownOpenUser((prevState) => !prevState);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const fetchMyUserInfo = async () => {
    const userInfo = await apiFactory().data.auth().getMyUser();
    setMyUserInfo(userInfo);
  };

  const fetchMyTasks = async () => {
    if (myUserInfo.length > 0) {
      if (myUserInfo[0].roleId === 2 || myUserInfo[0].roleId === 3) {
        const tasks = await apiFactory()
          .data.tasks()
          .getTasks(selectedProject.id, selectedUser.id);
        const newEvents = tasks.map((task) => {
          return {
            id: task.id,
            start: moment(task.startDate).toDate(),
            end: moment(task.deadlineDate).toDate(),
            title: task.projectName + ': ' + task.name /* + " - " + task.comment */,
          };
        });
        setEvents(newEvents);
        setTasks(tasks);
      } else {
        const tasks = await apiFactory()
          .data.users()
          .getUserTasks(myUserInfo[0].id);
        setTasks(tasks);
        const newEvents = tasks.map((task) => {
          return {
            id: task.id,
            start: moment(task.startDate).toDate(),
            end: moment(task.deadlineDate).toDate(),
            title: task.projectName + ': ' + task.name /* + " - " + task.comment */,
          };
        });
        setEvents(newEvents);
      }
      
    }
  };

  const fetchProjects = async () => {
    if (myUserInfo.length > 0) {
      const projectsResponse = await apiFactory().data.projects().getProjects();
      setProjects(projectsResponse);
    }
  };

  const fetchUsers = async () => {
    if (
      myUserInfo.length > 0 &&
      (myUserInfo[0].roleId === 2 || myUserInfo[0].roleId === 3)
    ) {
      const usersResponse = await apiFactory().data.users().getUsers();
      setUsers(usersResponse);
    }
  };

  useEffect(() => {
    fetchMyTasks();
  }, []);

  useEffect(() => {
    fetchMyUserInfo();
  }, [selectedProject, selectedUser]);

  useEffect(() => {
    fetchMyTasks();
    if (
      myUserInfo &&
      myUserInfo.length > 0 &&
      (myUserInfo[0].roleId === 2 || myUserInfo[0].roleId === 3)
    ) {
      fetchProjects();
      fetchUsers();
    }
  }, [myUserInfo]);

  const selectEventCalendar = (event) => {
    let task = tasks.find((x) => x.id === event.id);
    setSelectedTask(task);
    setOpenModal(true);
  };

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };
  return (
    <>
      <Header myUserInfo={myUserInfo} taskNumber={tasks.length} />
      {/* Page content */}
      {selectedTask !== {} && myUserInfo && myUserInfo.length > 0 ? (
        <TaskModal
          projectId={selectedTask.projectId}
          projectName={selectedTask.projectName}
          modalOpen={openModal}
          toggleModal={() => {
            setOpenModal(!openModal);
          }}
          viewOnly={myUserInfo[0].roleId === 1}
          handleRefresh={() => fetchMyUserInfo()}
          editDefaultValues={selectedTask}
        />
      ) : null}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Calendar
                    </h6>
                    <h2 className="mb-0">Taskuri curente</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div>
                  {myUserInfo &&
                    myUserInfo.length > 0 &&
                    (myUserInfo[0].roleId === 2 || myUserInfo[0].roleId === 3) ? (
                    <div className="mb-3">
                      <Dropdown
                        isOpen={dropdownOpen}
                        toggle={toggle}
                        className="m-2"
                      >
                        <DropdownToggle caret>
                          {selectedProject.name}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            key={0}
                            onClick={() => {
                              setSelectedProject({
                                id: 0,
                                name: "Selecteaza Proiect",
                              });
                            }}
                          >
                            Selecteaza Proiect
                          </DropdownItem>
                          {projects.map((x) => (
                            <DropdownItem
                              key={x.id}
                              onClick={() => {
                                setSelectedProject(x);
                              }}
                            >
                              {x.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>{" "}
                      <Dropdown
                        isOpen={dropdownOpenUser}
                        toggle={toggleUser}
                        className="m-2"
                      >
                        <DropdownToggle caret>
                          {selectedUser.userName}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            key={0}
                            onClick={() => {
                              setSelectedUser({
                                id: 0,
                                userName: "Selecteaza Utilizator",
                              });
                            }}
                          >
                            Selecteaza Utilizator
                          </DropdownItem>
                          {users.map((x) => (
                            <DropdownItem
                              key={x.id + "_user"}
                              onClick={() => {
                                setSelectedUser(x);
                              }}
                            >
                              {x.userName}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  ) : null}
                  <div style={{ width: "100%" }}>
                    <Calendar
                      localizer={localizer}
                      defaultDate={new Date()}
                      defaultView="agenda"
                      events={events}
                      startAccessor="start"
                      endAccessor="end"
                      onSelectEvent={selectEventCalendar}
                      style={{
                        fontSize: 11,
                        height: "70vh" /* , minWidth: "300px" */,
                      }}
                      views={["day", "week", "month", "agenda"]}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
