import React, { useEffect, useState, useRef } from "react";
import { apiFactory } from "api";
import { ClockLoader } from "react-spinners";
import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  FormGroup,
  Input,
  Alert,
  Label
} from "reactstrap";
import { css } from "@emotion/core";
import { useLocation, useHistory } from 'react-router-dom';
import { checkUserPermissions } from 'utils/authHelpers';
import { EXCLUDED_PROJECT_STATES, EXCLUDED_TASK_STATES } from 'api/utils/consts';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const AttendenceClock = () => {
  const [project, setProject] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [comment, setComment] = useState("");
  const [color, setColor] = useState("#00aabc");
  const [myUserInfo, setMyUserInfo] = useState([]);
  const [projects, setProjects] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showError, setShowError] = useState(false);
  const [hoursYesterday, setHoursYesterday] = useState(0);
  const [hoursLastWeek, setHoursLastWeek] = useState(0);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [elapsedTime, setElapsedTime] = useState("00:00:00");
  const stopwatchInterval = useRef(null);
  
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const authorize = async () => {
      const authorized = await checkUserPermissions(location.pathname, history);
      if (authorized) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    };
    authorize();
  }, [location.pathname, history]);

  useEffect(() => {
    if (isAuthorized) {
      latestClocking();
      fetchMyUserInfo();
      getProjects();
      calculateWorkedHours();
    }
  }, [isAuthorized]);

  const startStopwatch = (startTime) => {
    if (stopwatchInterval.current) {
      clearInterval(stopwatchInterval.current);
    }

    stopwatchInterval.current = setInterval(() => {
      const duration = moment.duration(moment().diff(startTime));
      setElapsedTime(
        `${String(duration.hours()).padStart(2, "0")}:${String(duration.minutes()).padStart(2, "0")}:${String(duration.seconds()).padStart(2, "0")}`
      );
    }, 1000);
  };

  const handleStart = async () => {
    try {
      const clockingInfo = { 
        comment: comment, 
        taskId: selectedTask ? selectedTask.id : null 
      }; 
  
      const response = await apiFactory().data.clockings().createClocking(clockingInfo);
      if (response[0].start !== null && response[0].stop === null) {
        setIsActive(true);
        startStopwatch(moment(response[0].start));
      }
    } catch (err) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  };

  const handleReset = async () => {
    try {
      const clockingInfo = { 
        comment: comment, 
        taskId: selectedTask ? selectedTask.id : null 
      }; 
  
      const response = await apiFactory().data.clockings().createClocking(clockingInfo);
      if (response[0].start !== null && response[0].stop !== null) {
        setModal(!modal);
        setIsActive(false);
        clearInterval(stopwatchInterval.current);
        setElapsedTime("00:00:00");
      }
    } catch (err) {
      setModal(!modal);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  };

  const handleChange = async (event) => {
    const selected = projects.find(
        (project) => project.id === parseInt(event.target.value)
    );
    setSelectedProject(selected);

    // Set selectedTask to null on project change
    setSelectedTask(null);
    setTasks([]);

    const projectTasks = await apiFactory().data.tasks().getTasks(selected.id, undefined);
    const filteredTasks = projectTasks.filter(
        (projectTasks) => !EXCLUDED_TASK_STATES.includes(projectTasks.stateName)
    );
    setTasks(filteredTasks);

    await apiFactory()
        .data.users()
        .updateUser({ currentProjectId: selected.id }, myUserInfo.id);
};

const latestClocking = async () => {
  const clockingInfo = await apiFactory().data.clockings().latestClocking();
  setProject(clockingInfo);

  if (clockingInfo.length > 0 && !clockingInfo[0].stop) {
      setIsActive(true);
      startStopwatch(moment(clockingInfo[0].start));

      if (clockingInfo[0].idTask) {
          const projectTasks = await apiFactory().data.tasks().getTasks(clockingInfo[0].idProject, undefined);
          const filteredTasks = projectTasks.filter(
              (task) => !EXCLUDED_TASK_STATES.includes(task.stateName)
          );
          setTasks(filteredTasks);

          const selectedTaskFromClocking = filteredTasks.find(
              (task) => task.id === clockingInfo[0].idTask
          );

          setSelectedTask(prev => selectedTaskFromClocking || prev);
      }
  }
};


  const fetchMyUserInfo = async () => {
    const userInfo = await apiFactory().data.auth().getMyUser();
    setMyUserInfo(userInfo[0]);
    const filteredProjects = projects.filter(
      (project) => !EXCLUDED_PROJECT_STATES.includes(project.state)
    );

    const userProject = filteredProjects.find(
      (project) => project.id === userInfo[0].currentProjectId
    );

    setSelectedProject(userProject);

    if (userInfo[0].currentProjectId) {
      const projectTasks = await apiFactory().data.tasks().getTasks(userInfo[0].currentProjectId, undefined);
      const filteredTasks = projectTasks.filter(
        (projectTasks) => !EXCLUDED_TASK_STATES.includes(projectTasks.stateName)
      );
      setTasks(filteredTasks);

      // Find and set the selected task if available
      const selectedTask = filteredTasks.find(
        (task) => task.id === userInfo[0].currentTaskId
      );
      if (!selectedTask){
      setSelectedTask(selectedTask || null);
      }
    }
};
  
  const getProjects = async () => {
    const projects = await apiFactory().data.projects().getProjects();
    setProjects(projects);
  };

  useEffect(() => {
    const loadInitialData = async () => {
        const loadedProjects = await apiFactory().data.projects().getProjects();
        const filteredProjects = loadedProjects.filter(
            (loadedProjects) => !EXCLUDED_PROJECT_STATES.includes(loadedProjects.state)
        );
        setProjects(filteredProjects);

        const userInfo = await apiFactory().data.auth().getMyUser();
        setMyUserInfo(userInfo[0]);

        const userProject = filteredProjects.find(
            (project) => project.id === userInfo[0].currentProjectId
        );

        setSelectedProject(userProject);

        if (userInfo[0].currentProjectId) {
            const projectTasks = await apiFactory().data.tasks().getTasks(userInfo[0].currentProjectId, undefined);
            const filteredTasks = projectTasks.filter(
                (projectTasks) => !EXCLUDED_TASK_STATES.includes(projectTasks.stateName)
            );
            setTasks(filteredTasks);

            const selectedTaskFromUser = filteredTasks.find(
                (task) => task.id === userInfo[0].currentTaskId
            );
            setSelectedTask(selectedTaskFromUser || null);
        }

        calculateWorkedHours(userInfo[0].id);
    };

    loadInitialData().then(() => {
        latestClocking(); // Ensure latestClocking runs after initial data load
    });
}, [isAuthorized]);

  const calculateWorkedHours = async (userId) => {
    const startDateYesterday = moment().startOf('day').subtract(1, 'day');
    const endDateYesterday = moment().startOf('day').subtract(1, 'second');

    const startDateLastWeek = moment().subtract(1, 'week').startOf('week').add(1, 'day'); // Luni
    const endDateLastWeek = moment(startDateLastWeek).endOf('week').add(1, 'day'); // Duminica

    const clockingsYesterday = await apiFactory().data.clockings().getClockings(
      undefined,
      userId,
      undefined,
      undefined,
      startDateYesterday.format("YYYY-MM-DD HH:mm:ss"),
      endDateYesterday.format("YYYY-MM-DD HH:mm:ss")
    );
    const clockingsLastWeek = await apiFactory().data.clockings().getClockings(
      undefined,
      userId,
      undefined,
      undefined,
      startDateLastWeek.format("YYYY-MM-DD HH:mm:ss"),
      endDateLastWeek.format("YYYY-MM-DD HH:mm:ss")
    );
  
    let totalMinutesYesterday = 0;
    clockingsYesterday.forEach((clocking) => {
      const start = moment(clocking.start);
      const stop = moment(clocking.stop);
      totalMinutesYesterday += stop.diff(start, 'minutes');
    });
  
    const hoursYesterday = Math.floor(totalMinutesYesterday / 60);
    const minutesYesterday = totalMinutesYesterday % 60;
  
    let totalMinutesLastWeek = 0;
    clockingsLastWeek.forEach((clocking) => {
      const start = moment(clocking.start);
      const stop = moment(clocking.stop);
      totalMinutesLastWeek += stop.diff(start, 'minutes');
    });
  
    const hoursLastWeek = Math.floor(totalMinutesLastWeek / 60);
    const minutesLastWeek = totalMinutesLastWeek % 60;
  
    setHoursYesterday(`${hoursYesterday} ore ${minutesYesterday} min`);
    setHoursLastWeek(`${hoursLastWeek} ore ${minutesLastWeek} min`);
  };
  
  const handleTaskChange = (event) => {
    const selected = tasks.find(task => task.id === parseInt(event.target.value));
    setSelectedTask(selected);
  };
  
  useEffect(() => {
    if (modal === false) setComment("");
  }, [modal]);

  if (!isAuthorized) {
    return (
      <Container fluid>
        <Row>
          <Col className="text-center">
            <h1>Not Authorized</h1>
            <p>You do not have permission to view this page.</p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-gradient-danger"
        isOpen={modal}
        toggle={() => setModal(!modal)}>
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setModal(!modal)}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className=" text-center">
            <i className="ni ni-bell-55 ni-3x" />
            <h4 className="heading mt-4">
              Ești sigur ca dorești să oprești ceasul de pontaj?
            </h4>
          </div>
          <div className="mt-5 ml-2 mr-2">
            <FormGroup>
              <label style={{ fontWeight: "300" }}>Descriere:</label>
              <Input
                type="textarea"
                className="form-control-alternative"
                placeholder="Adaugă o descriere pentru a opri pontarea"
                onChange={(e) => {
                  e.target.value?.length > 0 ? setDisabled(false) : setDisabled(true);
                  setComment(e.target.value);
                }}
                maxLength="145"
                rows="5"
              />
            </FormGroup>
          </div>
        </div>
  
        <div className="modal-footer pt-0">
          <Button
            className="text-white pr-4 pl-4"
            color="link"
            data-dismiss="modal"
            type="button"
            size="lg"
            onClick={() => setModal(!modal)}>
            Nu
          </Button>
          <Button
            className="btn-white pr-4 pl-4 ml-auto "
            color="default"
            type="button"
            size="lg"
            disabled={disabled}
            onClick={() => handleReset()}>
            Da
          </Button>
        </div>
      </Modal>
  
      <Row className="justify-content-center">
        {showError && (
          <Col xs="12" md="12" xl="6" className="mt-4">
            <Alert color="warning" className="mb-0">
              <strong>Eroare!</strong> Ceva nu a mers bine. Mai încearcă o dată sau
              reîncarcă pagina.
            </Alert>
          </Col>
        )}
        <Col xs="12" md="12" xl="6" className="mb-4 m-4">
          <Card className="card-profile shadow">
            {myUserInfo.length !== 0 && (
              <CardBody className="pt-0 pb-0">
                <Row className="justify-content-center mt-4">
                  <Col xs="12">
                    <div className="text-center">
                      <span className="heading">{myUserInfo.firstName + " " + myUserInfo.lastName}</span>
                      <br />
                      <span className="description">{myUserInfo.userName}</span>
                    </div>
                  </Col>
                </Row>
  
                <Row className="mt-4 text-center">
                  <Col xs="6">
                    <div className="card-profile-stats card-profile-clocking">
                      <span className="description">Zi anterioara:&nbsp;</span>
                      <br />
                      <span className="bold pb-2">{hoursYesterday}</span>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="card-profile-stats card-profile-clocking">
                      <span className="description">Saptamana anterioara:&nbsp; </span>
                      <br />
                      <span className="bold pb-2">{hoursLastWeek}</span>
                    </div>
                  </Col>
                </Row>
  
                <Row className="justify-content-center mt-4">
                  <Col xs="4" md="2" className="text-right">
                    <Label for="projectSelect" className="heading">Proiect:</Label>
                  </Col>
                  <Col xs="8" md="6">
                    <Input
                      type="select"
                      name="roleId"
                      id="projectSelect"
                      className="form-control"
                      disabled={isActive}
                      value={selectedProject ? selectedProject.id : ''}
                      onChange={(e) => handleChange(e)}>
                      <option value="" disabled>Select a project</option>
                      {projects !== null && (
                        <>
                          {projects.map((project) => {
                            return (
                              <option value={project.id} key={"role_" + project.id}>
                                {project.name}
                              </option>
                            );
                          })}
                        </>
                      )}
                    </Input>
                  </Col>
                </Row>
  
                {tasks.length > 0 && (
                  <Row className="justify-content-center mt-4">
                    <Col xs="4" md="2" className="text-right">
                      <Label for="taskSelect" className="heading">Task:</Label>
                    </Col>
                    <Col xs="8" md="6">
                      <Input
                        type="select"
                        name="taskId"
                        id="taskSelect"
                        className="form-control"
                        disabled={isActive || tasks.length === 0}
                        value={selectedTask ? selectedTask.id : ''}
                        onChange={handleTaskChange}>
                        <option value="" disabled>Select a task (optional)</option>
                        {tasks.map((task) => (
                          <option value={task.id} key={"task_" + task.id}>
                            {task.name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                )}
  
                <Row className="mt-4 justify-content-center align-items-center">
                  {isActive ? (
                    <>
                      <Col xs="12" md="4" className="text-center mb-3 mb-md-0">
                        <div className="align-items-center">
                          <ClockLoader color={color} css={override} size={60} />
                        </div>
                        <div className="mt-2">
                          <h4>{elapsedTime}</h4> {/* Display the stopwatch */}
                        </div>
                      </Col>
                      <Col xs="12" md="4" className="text-center">
                        <Button
                          type="button"
                          color="primary"
                          size="lg"
                          className="btn-icon-only rounded-circle"
                          style={{ width: "8rem", height: "8rem" }}
                          onClick={() => setModal(!modal)}>
                          <i className="ni ni-button-pause" style={{ fontSize: "3rem" }} />
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <Col xs="12" className="text-center">
                      <Button
                        type="button"
                        color="success"
                        size="lg"
                        className="btn-icon-only rounded-circle"
                        style={{ width: "10rem", height: "10rem" }}
                        onClick={handleStart}>
                        <i className="ni ni-button-play" style={{ fontSize: "4rem" }} />
                      </Button>
                    </Col>
                  )}
                </Row>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AttendenceClock;
