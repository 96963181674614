import { apiFactory } from "api";
import { useMediaQuery } from "beautiful-react-hooks";
import UsersTable from "components/Tables/UsersTable.js";
import UsersModal from "components/Modals/UsersModal.js";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  Button,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useLocation, useHistory } from 'react-router-dom';
import { checkUserPermissions } from 'utils/authHelpers';

const Users = () => {
  const isSmall = useMediaQuery("(max-width: 57rem)");
  const [modalOpen, setModalOpen] = useState(false);
  const [projectNames, setProjectNames] = useState([]);
  const [projects, setProjects] = useState([]);
  const [teams, setTeams] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedProject, setSelectedProject] = useState({
    id: 0,
    name: "Selectează Proiect",
  });
  const [selectedRole, setSelectedRole] = useState({
    id: 0,
    name: "Selectează Rol",
  });
  const [selectedTeam, setSelectedTeam] = useState({
    id: 0,
    name: "Selectează Echipa",
  });
  const [users, setUsers] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenRole, setDropdownOpenRole] = useState(false);
  const [dropdownOpenTeam, setDropdownOpenTeam] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleRole = () => setDropdownOpenRole((prevState) => !prevState);
  const toggleTeam = () => setDropdownOpenTeam((prevState) => !prevState);
  const fetchUsers = async () => {
    const newUsers = await apiFactory()
      .data.users()
      .getUsers(selectedProject.id, selectedTeam.id, selectedRole.id);
    setUsers(newUsers);
  };

  const [isAuthorized, setIsAuthorized] = useState(true);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const authorize = async () => {
      const authorized = await checkUserPermissions(location.pathname, history);
      if (authorized) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    };
    authorize();
  }, [location.pathname, history]);

  useEffect(() => {
    if (isAuthorized) {
      fetchUsers();
      fetchProjects();
      fetchRoles();
      fetchTeams();
    }
  }, [isAuthorized],[selectedProject, selectedRole, selectedTeam]);

  const fetchProjects = async () => {
    const projectsResponse = await apiFactory().data.projects().getProjects();
    setProjects(projectsResponse);
  };

  const fetchRoles = async () => {
    const rolesResponse = await apiFactory().data.users().getRoles();
    setRoles(rolesResponse);
  };

  const fetchTeams = async () => {
    const teamsResponse = await apiFactory().data.users().getTeams();
    setTeams(teamsResponse);
  };

  const handleRefresh = () => {
    fetchUsers();
    fetchProjects();
    ;
  };

  if (!isAuthorized) {
    return (
      <Container fluid>
        <Row>
          <Col className="text-center">
            <h1>Not Authorized</h1>
            <p>You do not have permission to view this page.</p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col xxs="12" className="mb-4 mt-4">
            <Card className="mb-4 shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Utilizatori</h3>
                  </Col>
                  <Col xs="6" className="text-right">
                    <Button
                      type="button"
                      color="primary"
                      size="sm"
                      className="btn-icon btn-1"
                      onClick={() => setModalOpen(true)}
                    >
                      {/* <span className="btn-inner--icon mr-1"> */}
                      <i className="fas fa-user-edit mr-2" />
                      {/* </span> */}
                      Adaugă utilizator
                    </Button>
                  </Col>
                </Row>
                {/* <div className="d-flex justify-content-between">
                  <h2 className="mb-0">Utilizator</h2>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => setModalOpen(true)}
                  >
                    Adaugă utilizator
                  </button>
                </div> */}
              </CardHeader>
              <div className="mb-3">
                <Dropdown isOpen={dropdownOpen} toggle={toggle} className="m-2">
                  <DropdownToggle caret>{selectedProject.name}</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      key={0}
                      onClick={() => {
                        setSelectedProject({
                          id: 0,
                          name: "Selecteaza Proiect",
                        });
                      }}
                    >
                      Selecteaza Proiect
                    </DropdownItem>
                    {projects.map((x) => (
                      <DropdownItem
                        key={x.id}
                        onClick={() => {
                          setSelectedProject(x);
                        }}
                      >
                        {x.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>{" "}
                <Dropdown
                  isOpen={dropdownOpenRole}
                  toggle={toggleRole}
                  className="m-2"
                >
                  <DropdownToggle caret>{selectedRole.name}</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      key={0}
                      onClick={() => {
                        setSelectedRole({
                          id: 0,
                          name: "Selecteaza Rol",
                        });
                      }}
                    >
                      Selecteaza Rol
                    </DropdownItem>
                    {roles.map((x) => (
                      <DropdownItem
                        key={x.id}
                        onClick={() => {
                          setSelectedRole(x);
                        }}
                      >
                        {x.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>{" "}
                <Dropdown
                  isOpen={dropdownOpenTeam}
                  toggle={toggleTeam}
                  className="m-2"
                >
                  <DropdownToggle caret>{selectedTeam.name}</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      key={0}
                      onClick={() => {
                        setSelectedTeam({
                          id: 0,
                          name: "Selecteaza Echipa",
                        });
                      }}
                    >
                      Selecteaza Echipa
                    </DropdownItem>
                    {teams.map((x) => (
                      <DropdownItem
                        key={x.id}
                        onClick={() => {
                          setSelectedTeam(x);
                        }}
                      >
                        {x.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>{" "}
              </div>
              <UsersTable users={users} handleRefresh={handleRefresh} />
            </Card>
          </Col>
        </Row>
        <UsersModal
          modalOpen={modalOpen}
          toggleModal={() => {
            setModalOpen(!modalOpen);
          }}
          handleRefresh={handleRefresh}
          addMode={true}
        />
      </Container>
    </>
  );
};
export default Users;
