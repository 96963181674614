import { PAResponse, Users } from "api/users";
import { sendGetRequest, sendPostRequest, sendPutRequest } from "api/utils";
import { ErrorInterface } from "api/utils/types";
import { api_url } from "../utils/consts";
import {
  Necessities,
  NecessitiesResponse,
  NewNecessity,
  ProjectResponse,
  Projects,
  State,
  StateResponse,
  Task,
  TaskResponse,
  UsersProject,
} from "./types";

export default () => ({
  getProjects: async (): Promise<Projects[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || "";
      const { data }: ProjectResponse = await sendGetRequest(
        api_url + "/api/v1.0/tm/project",
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  getProjectById: async (id: number): Promise<Projects[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || "";
      const { data }: ProjectResponse = await sendGetRequest(
        api_url + "/api/v1.0/tm/project/?id=" + id,
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  createProject: async (
    project: Partial<Projects>
  ): Promise<Projects[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || "";
      const { data }: ProjectResponse = await sendPostRequest(
        api_url + "/api/v1.0/tm/project",
        project,
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  createTask: async (task: Partial<Task>): Promise<Task[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || "";
      const { data }: TaskResponse = await sendPostRequest(
        api_url + "/api/v1.0/tm/task",
        task,
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  updateProject: async (
    project: Partial<Projects>,
    id: number
  ): Promise<Projects[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || "";
      const { data }: ProjectResponse = await sendPutRequest(
        api_url + "/api/v1.0/tm/project/" + id,
        project,
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  updateTask: async (
    task: Partial<Projects>,
    id: number
  ): Promise<Projects[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || "";
      const { data }: ProjectResponse = await sendPutRequest(
        api_url + "/api/v1.0/tm/task/" + id,
        task,
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  getStateProjects: async (): Promise<State[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || "";
      const { data }: StateResponse = await sendGetRequest(
        api_url + "/api/v1.0/tm/projectState",
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  getProjectTasks: async (id: number): Promise<Task[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || "";
      const { data }: TaskResponse = await sendGetRequest(
        api_url + "/api/v1.0/tm/task/?projectId=" + id,
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  getProjectUsers: async (id: number): Promise<Users[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || "";
      const { data }: PAResponse = await sendGetRequest(
        api_url + "/api/v1.0/tm/user/?currentProjectId=" + id,
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  getStatusTasks: async (): Promise<State[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || "";
      const { data }: StateResponse = await sendGetRequest(
        api_url + "/api/v1.0/tm/state",
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  getNecessities: async (
    id: number
  ): Promise<Necessities[] | ErrorInterface> => {
    try {
      const token = localStorage.getItem("token") || "";
      const { data }: NecessitiesResponse = await sendGetRequest(
        api_url + "/api/v1.0/tm/necessity?idProject=" + id,
        token
      );
      return data;
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  postNecessities: async (necessity: NewNecessity) => {
    try {
      const token = localStorage.getItem("token") || "";
      await sendPostRequest(
        api_url + "/api/v1.0/tm/necessity",
        necessity,
        token
      );
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
  putNecessities: async (id, necessity: NewNecessity) => {
    try {
      const token = localStorage.getItem("token") || "";
      await sendPutRequest(
        api_url + "/api/v1.0/tm/necessity/" + id,
        necessity,
        token
      );
    } catch (error) {
      return {
        message: "Eroare.",
        errorCode: "400",
      };
    }
  },
});
