import { apiFactory } from "api";
import { useMediaQuery } from "beautiful-react-hooks";
import TeamModal from "components/Modals/TeamModal.js";
import TeamsTable from "components/Tables/TeamsTable.js";
import React, { useEffect, useState } from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { useLocation, useHistory } from 'react-router-dom';
import { checkUserPermissions } from 'utils/authHelpers';

const Teams = () => {
  const isSmall = useMediaQuery("(max-width: 57rem)");
  const [modalOpen, setModalOpen] = useState(false);
  const [teams, setTeams] = useState([]);

  const fetchNewTeams = async () => {
    const newTeams = await apiFactory().data.users().getTeams();
    setTeams(newTeams);
  };

  const [isAuthorized, setIsAuthorized] = useState(true);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const authorize = async () => {
      const authorized = await checkUserPermissions(location.pathname, history);
      if (authorized) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    };
    authorize();
  }, [location.pathname, history]);

  useEffect(() => {
    if (isAuthorized) {
      fetchNewTeams();
    }
  }, [isAuthorized]);
  
  const handleRefresh = () => {
    fetchNewTeams();
  };

  if (!isAuthorized) {
    return (
      <Container fluid>
        <Row>
          <Col className="text-center">
            <h1>Not Authorized</h1>
            <p>You do not have permission to view this page.</p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Row>
        {teams && teams.length > 0 ? (
          <Col xxs="12" className="mb-4 mt-4">
            <Card className="mb-4 shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between">
                  <h2 className="mb-0">Echipe</h2>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => setModalOpen(true)}
                  >
                    Adauga o echipă nouă
                  </button>
                </div>
              </CardHeader>
              <TeamsTable teams={teams} handleRefresh={handleRefresh} />
            </Card>
          </Col>
        ) : (
          <div
            className="flex-column d-flex w-100 justify-content-center align-item-center"
            style={{ height: "80vh" }}
          >
            <div className="flex-row d-flex justify-content-center align-item-center">
              <div
                className={
                  "flex-column d-flex justify-content-center align-item-center " +
                  (isSmall ? "pl-3 pr-3" : null)
                }
              >
                <p
                  className="align-item-center justify-content-center d-flex text-lmedium  font-weight-semibold"
                  style={{ textAlign: "center" }}
                >
                  Deocamdata nu ai nici o echipă adăugată.
                </p>

                <div className="align-item-center justify-content-center d-flex ">
                  <button
                    className="btn btn-primary mt-3"
                    onClick={() => {
                      setModalOpen(!modalOpen);
                    }}
                    style={{ maxWidth: "300px", width: "300px" }}
                  >
                    Adauga mai multe echipe!
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Row>
      <TeamModal
        modalOpen={modalOpen}
        toggleModal={() => {
          setModalOpen(!modalOpen);
        }}
        handleRefresh={handleRefresh}
      />
    </Container>
  );
};
export default Teams;
