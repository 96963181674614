import React, { useState, useEffect, useMemo } from "react";
import * as yup from "yup";
import "yup-phone";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";

import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  Card,
} from "reactstrap";

import { apiFactory } from "api";

const validationSchema = yup.object().shape({
  name: yup.string().required("Camp obligatoriu"),
  email: yup.string().email("Email-ul nu e valid"),
});

const ClientModal = ({
  modalOpen,
  toggleModal,
  handleRefresh,
  editDefaultValues,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    errors,
    formState,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    defaultValues: useMemo(() => editDefaultValues, [editDefaultValues]),
  });

  const onSubmit = async (data) => {
    const updateUser = async () => {
      await apiFactory()
        .data.clients()
        .updateClient(data, editDefaultValues.id);
      handleRefresh();
    };
    const createUser = async () => {
      await apiFactory().data.clients().createClient({
        name: data.name,
        phone: data.phone,
        email: data.email,
      });
      handleRefresh();
    };

    if (_.isEmpty(editDefaultValues)) {
      createUser();
    } else {
      updateUser();
    }
    toggleModal();
  };

  useEffect(() => {
    setTimeout(() => {
      if (!_.isEmpty(editDefaultValues)) {
        for (var key of Object.keys(editDefaultValues)) {
          setValue(key, editDefaultValues[key]);
        }
      }
    });
  }, [editDefaultValues, modalOpen]);

  return (
    <Modal
      isOpen={modalOpen}
      toggle={toggleModal}
      wrapClassName='modal-right bg-secondary '
      backdrop='static'>
      <Card className='bg-secondary shadow border-0'>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggleModal}>Client</ModalHeader>
          <ModalBody>
            <Label>Nume</Label>
            <Input
              innerRef={register}
              type='text'
              name='name'
              maxLength={255}
              placeholder=''
              className='form-control-alternative'
            />
            <p className='text-danger font-weight-normal text-xs mt-2'>
              {errors.name?.message}
            </p>

            <Label>Numar de telefon</Label>
            <Input
              innerRef={register}
              type='text'
              name='phone'
              placeholder=''
              className='form-control-alternative'
            />
            <p className='text-danger font-weight-normal text-xs mt-2'>
              {errors.phone?.message}
            </p>

            <Label>Email</Label>
            <Input
              innerRef={register}
              type='text'
              name='email'
              placeholder=''
              className='form-control-alternative'
            />
            <p className='text-danger font-weight-normal text-xs mt-2'>
              {errors.email?.message}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={toggleModal}>
              Renunță
            </Button>
            <Button color='primary' type='submit'>
              Salvează
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Card>
    </Modal>
  );
};

export default ClientModal;
