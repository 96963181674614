import Clients from 'views/examples/Clients.js';
import Clocking from 'views/examples/Clocking.js';
import EditProjects from 'views/examples/EditProjects.js';
import EditTasks from 'views/examples/EditTasks.js';
import Login from 'views/examples/Login.js';
import Projects from 'views/examples/Projects.js';
import Teams from 'views/examples/Teams.js';
import Users from 'views/examples/Users.js';
import Index from 'views/Index.js';
import AttendenceClock from './views/examples/AttendenceClock';
import ClockingDash from './views/examples/ClockingDash';
import ClockingProject from './views/examples/ClockingProject';
import Devices from './views/examples/Devices';
import Necessities from './views/examples/Necessities';

var routes = [
  {
    path: '/index',
    name: 'Panou principal',
    icon: 'ni ni-tv-2 text-primary',
    component: Index,
    layout: '/admin',
    notPermitedRoleIds: [],
  },
  {
    path: '/clients',
    name: 'Clienți',
    icon: 'ni ni-badge text-gray',
    component: Clients,
    layout: '/admin',
    notPermitedRoleIds: [1],
  },
  {
    path: '/projects/:id/tasks',
    name: 'EditTasks',
    icon: 'ni ni-books text-orange',
    component: EditTasks,
    layout: '/admin',
    notPermitedRoleIds: [1],
  },
  {
    path: '/projects/:id',
    name: 'EditProject',
    icon: 'ni ni-books text-orange',
    component: EditProjects,
    layout: '/admin',
    notPermitedRoleIds: [1],
  },

  {
    path: '/project/clockings/:id',
    name: 'fas fa-clock',
    icon: 'ni ni-books text-orange',
    component: ClockingProject,
    layout: '/admin',
    notPermitedRoleIds: [1],
  },
  {
    path: '/project/necessities/:id',
    name: 'fas fa-clock',
    icon: 'ni ni-books text-orange',
    component: Necessities,
    layout: '/admin',
    notPermitedRoleIds: [1],
  },
  {
    path: '/projects',
    name: 'Proiecte',
    icon: 'ni ni-books text-orange',
    component: Projects,
    layout: '/admin',
    notPermitedRoleIds: [1],
  },
  {
    path: '/users',
    name: 'Utilizatori',
    icon: 'ni ni-circle-08',
    component: Users,
    layout: '/admin',
    notPermitedRoleIds: [1, 3],
  },
  {
    path: '/teams',
    name: 'Echipe',
    icon: 'fa fa-users',
    component: Teams,
    layout: '/admin',
    notPermitedRoleIds: [1, 3],
  },
  {
    path: '/devices',
    name: 'Devices',
    icon: 'fa fa-microchip text-orange',
    component: Devices,
    layout: '/admin',
    notPermitedRoleIds: [1],
  },
  {
    path: '/clockings',
    name: 'Pontaj',
    icon: 'ni ni-watch-time text-info',
    component: Clocking,
    layout: '/admin',
    notPermitedRoleIds: [],
  },
  {
    path: '/attendenceClock',
    name: 'Ceas Pontaj',
    icon: 'ni ni-watch-time',
    component: AttendenceClock,
    layout: '/admin',
    notPermitedRoleIds: [],
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth',
    notPermitedRoleIds: [],
  },
  {
    path: '/tv',
    name: 'Tablou Pontaj',
    icon: 'ni ni-tv-2 text-info',
    component: ClockingDash,
    layout: '/fullpage',
    notPermitedRoleIds: [1],
  },
];
export default routes;
