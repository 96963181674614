import {yupResolver} from '@hookform/resolvers/yup';
import {apiFactory} from 'api';
import _ from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {
  Button,
  Card,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as yup from 'yup';
import 'yup-phone';

const validationSchema = yup.object().shape({
  // name: yup.string().required('Camp obligatoriu'),
});

const DeviceModal = ({
  modalOpen,
  toggleModal,
  handleRefresh,
  editDefaultValues,
  hasErrors,
}) => {
  const {register, handleSubmit, watch, setValue, errors, formState} = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => editDefaultValues, [editDefaultValues]),
  });

  const [triggeredReset, setTriggeredReset] = useState(false);

  const resetDevice = async () => {
    const responseObj = await apiFactory()
      .data.devices()
      .updateDevice(editDefaultValues.id, {requestReset: 1});
    if (responseObj.requestReset === 1) {
      editDefaultValues.requestReset = 1;
      console.log(responseObj);
      console.log(editDefaultValues);
      setTriggeredReset(true);
    } else {
      alert('Something went wrong!');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (!_.isEmpty(editDefaultValues)) {
        for (var key of Object.keys(editDefaultValues)) {
          setValue(key, editDefaultValues[key]);
        }
      }
    });
  }, [editDefaultValues, modalOpen]);

  useEffect(() => {
    if (!modalOpen) {
      handleRefresh();
    }
    setTimeout(() => {
      setTriggeredReset(false);
    }, 500);
  }, [modalOpen]);
  return (
    <Modal
      isOpen={modalOpen}
      toggle={toggleModal}
      wrapClassName="modal-right bg-secondary "
      backdrop="static"
      hasErrors={hasErrors}
    >
      <Card className="bg-secondary shadow border-0">
        <Form>
          <ModalHeader toggle={toggleModal}>Device</ModalHeader>
          <ModalBody className="pt-0">
            <Label>Name</Label>
            <Input
              innerRef={register}
              type="text"
              name="fullName"
              maxLength={4}
              placeholder=""
              className="form-control-alternative text-black"
              disabled={true}
            />
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.name?.message}
            </p>
            <Label>Serial Number</Label>
            <Input
              innerRef={register}
              type="text"
              name="serialNumber"
              maxLength={4}
              placeholder=""
              className="form-control-alternative text-black"
              disabled={true}
            />
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.name?.message}
            </p>
            <Label>Status</Label>
            <Input
              innerRef={register}
              type="text"
              name="status"
              maxLength={4}
              placeholder=""
              className="form-control-alternative text-black"
              disabled={true}
            />
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.name?.message}
            </p>
            <Label>Errors</Label>
            <Input
              innerRef={register}
              type="text"
              name="hasErrors"
              maxLength={4}
              placeholder=""
              style={
                hasErrors === 'No'
                  ? {backgroundColor: '#2dce89'}
                  : {backgroundColor: '#f5365c'}
              }
              className="form-control-alternative text-white"
              disabled={true}
            />
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.name?.message}
            </p>
            <Label>Shield SW Version</Label>
            <Input
              innerRef={register}
              type="text"
              name="shieldSw"
              maxLength={4}
              placeholder=""
              className="form-control-alternative text-black"
              disabled={true}
            />
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.name?.message}
            </p>
            <Label>Shield HW Version</Label>
            <Input
              innerRef={register}
              type="text"
              name="shieldHw"
              maxLength={4}
              placeholder=""
              className="form-control-alternative text-black"
              disabled={true}
            />
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.name?.message}
            </p>
            <Label>Mixlight SW Version</Label>
            <Input
              innerRef={register}
              type="text"
              name="mixlightSw"
              maxLength={4}
              placeholder=""
              className="form-control-alternative text-black"
              disabled={true}
            />
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.name?.message}
            </p>
            <Label>Uptime</Label>
            <Input
              innerRef={register}
              type="text"
              name="uptime"
              maxLength={4}
              placeholder=""
              className="form-control-alternative text-black"
              disabled={true}
            />
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.name?.message}
            </p>
            <Label>Public IP</Label>
            <Input
              innerRef={register}
              type="text"
              name="publicIp"
              maxLength={4}
              placeholder=""
              className="form-control-alternative text-black"
              disabled={true}
            />
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.name?.message}
            </p>
            <Label>Local IP</Label>
            <Input
              innerRef={register}
              type="text"
              name="localIp"
              maxLength={4}
              placeholder=""
              className="form-control-alternative text-black"
              disabled={true}
            />
            <p className="text-danger font-weight-normal text-xs mt-2">
              {errors.name?.message}
            </p>
          </ModalBody>
          <ModalFooter
            className="pt-0"
            style={{justifyContent: 'space-between', alignItems: 'flex-end'}}
          >
            <div>
              {editDefaultValues.requestReset === 1 && triggeredReset ? (
                <h2 className="text-danger ">Resetting Device!</h2>
              ) : editDefaultValues.requestReset === 1 ? (
                <h2 className="text-danger ">Device already in reset!</h2>
              ) : (
                <></>
              )}
              <Button
                color={
                  editDefaultValues.requestReset === 0 && !triggeredReset
                    ? 'danger'
                    : 'danger btn-outline-danger'
                }
                onClick={() => resetDevice()}
                disabled={
                  editDefaultValues.requestReset === 0 && !triggeredReset
                    ? false
                    : true
                }
              >
                Resetează Device
              </Button>{' '}
            </div>
            <Button color="secondary" onClick={toggleModal}>
              Înapoi
            </Button>
          </ModalFooter>
        </Form>
      </Card>
    </Modal>
  );
};

export default DeviceModal;
