import classnames from "classnames";
// import DatatablePagination from "components/DatatablePagination";
import React, { useState } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
// import AddressModal from "components/account/editAddressModal";
import DatatablePagination from "components/DatatablePagination";

import { apiFactory } from "api";
import TeamModal from "components/Modals/TeamModal.js";

function TableDefined({
  columns,
  data,
  divided = false,
  defaultPageSize = 10,
  setModalBasic,
  setForDelete,
  setModalLarge,
  setForModal,
  handleRefresh,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: defaultPageSize },
    },
    useSortBy,
    usePagination
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [editValues, setEditValues] = useState({});

  const truncString = (string) => {
    if (string && string.length >= 27) {
      return string.slice(0, 27) + "...";
    }
    return string || "";
  };

  const openEditModal = (newData, id) => {
    setModalOpen(!modalOpen);
    setEditValues({ ...newData, id: id });
  };

  return (
    <>
      <TeamModal
        modalOpen={modalOpen}
        toggleModal={() => {
          setModalOpen(!modalOpen);
        }}
        handleRefresh={handleRefresh}
        editDefaultValues={editValues}
      />
      <table
        {...getTableProps()}
        className={`r-table table ${classnames({ "table-divided": divided })}`}
      >
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={`th_${columnIndex}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sorted-desc"
                        : "sorted-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                  <span />
                </th>
              ))}
              <th scope="col" />
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <td
                      key={`td_${cellIndex}`}
                      {...cell.getCellProps({
                        className: cell.column.cellClass,
                      })}
                      style={
                        cell.column.cellClass === "list-item-heading w-10"
                          ? { verticalAlign: "middle", minWidth: "50px" }
                          : { verticalAlign: "middle", minWidth: "25px" }
                      }
                    >
                      {cell.column.cellClass === "list-item-heading w-10" ? (
                        <div
                          className="fake-a-tag"
                          style={{ color: "#39a36f" }}
                          onClick={() => {
                            setModalLarge(true);
                            setForModal(cell.row.original);
                          }}
                        >
                          {truncString(cell.value, 30)}
                        </div>
                      ) : (
                        truncString(cell.value, 30)
                      )}
                    </td>
                  );
                })}
                <td key={`td_5`} style={{ minWidth: "120px" }}>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-outline-primary mr-1 btn-sm"
                      onClick={() => {
                        openEditModal(row.values, row.original.id);
                      }}
                    >
                      <i className="ni ni-settings pr-2" />
                      Editează
                    </button>
                    {/* {data.length <= 1 ? null : (
                      <button
                        className='btn btn-outline-danger btn-sm'
                        style={{ float: "right" }}
                        onClick={() => {
                          setModalBasic(true);
                          setForDelete(row.original.id);
                        }}>
                        <i className='ni ni-fat-remove' /> Sterge
                      </button>
                    )} */}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <DatatablePagination
        page={pageIndex}
        pages={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        pageSizeOptions={[5, 10, 20, 30, 40, 50]}
        showPageSizeOptions={true}
        showPageJump={false}
        defaultPageSize={pageSize}
        onPageChange={(p) => gotoPage(p)}
        onPageSizeChange={(s) => setPageSize(s)}
        paginationMaxSize={pageCount}
      />
    </>
  );
}

const TeamsTable = ({ teams, handleRefresh }) => {
  const [modalBasic, setModalBasic] = useState(false);
  const [forDelete, setForDelete] = useState();
  const [modalLarge, setModalLarge] = useState(false);
  const [forModal, setForModal] = useState();

  const cols = React.useMemo(
    () => [
      // {
      //   Header: "Id",
      //   accessor: "id",
      //   cellClass: "list-item-heading w-10",
      //   Cell: (props) => <>{props.value}</>,
      // },
      {
        Header: "Nume",
        accessor: "name",
        cellClass: "text-muted w-25",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  // const deleteAddress = async () => {
  //   await apiFactory().data.account().updateAddresses(forDelete, {
  //     isDeleted: 1,
  //   });
  //   handleRefresh();
  // };

  return (
    // <div className='table-responsive'>
    <>
      <Table className="align-items-center table-flush" responsive>
        <TableDefined
          columns={cols}
          data={teams}
          setModalBasic={setModalBasic}
          setForDelete={setForDelete}
          setModalLarge={setModalLarge}
          setForModal={setForModal}
          handleRefresh={handleRefresh}
          // divided
        />
      </Table>

      {/* <Modal isOpen={modalBasic} toggle={() => setModalBasic(!modalBasic)}>
        <ModalHeader
          style={{ borderBottom: "0px solid black" }}
          className="pb-0 text-lg"
        >
          <div className="text-lg pt-4 pb-2 m-4">
            Dorești să ștergi acest client?
          </div>
        </ModalHeader>
        <ModalFooter style={{ borderTop: "0px solid black" }}>
          <Button
            color="btn btn-secondary "
            onClick={() => setModalBasic(false)}
          >
            Renunță
          </Button>
          <Button
            color="danger"
            onClick={() => {
              // deleteAddress();
              setModalBasic(false);
            }}
          >
            Șterge
          </Button>
        </ModalFooter>
      </Modal> */}
    </>
  );
};

export default TeamsTable;
