import {apiFactory} from 'api';
import {useMediaQuery} from 'beautiful-react-hooks';
import React, {useEffect, useState} from 'react';
import {Card, CardHeader, Col, Container, Row} from 'reactstrap';
import DevicesTable from '../../components/Tables/DevicesTable';
import timeDiff from '../../utils/timeDiff';
import { useLocation, useHistory } from 'react-router-dom';
import { checkUserPermissions } from 'utils/authHelpers';

const Devices = () => {
  const isSmall = useMediaQuery('(max-width: 57rem)');
  const [modalOpen, setModalOpen] = useState(false);
  const [devices, setDevices] = useState([]);

  const fetchNewDevices = async () => {
    const newDevices = await apiFactory().data.devices().getDevices();
    newDevices.forEach((device) => {
      if (device.keepAlive) {
        var now = new Date();
        var deviceUptime = new Date(device.keepAlive.slice(0, -1) + '+02:00');
        const diffobj = timeDiff(deviceUptime, now);
        device.uptime = diffobj.stringFormatDaysDateShort;
      } else device.uptime = 'Invalid Date';
      device.hasErrors = device.errors === 1 ? 'Yes' : 'No';
    });
    setDevices(newDevices);
  };

  const [isAuthorized, setIsAuthorized] = useState(true);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const authorize = async () => {
      const authorized = await checkUserPermissions(location.pathname, history);
      if (authorized) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    };
    authorize();
  }, [location.pathname, history]);

  useEffect(() => {
    if (isAuthorized) {
      fetchNewDevices();
    }
  }, [isAuthorized]);

  const handleRefresh = () => {
    fetchNewDevices();
  };

  useEffect(() => {}, [devices]);

  if (!isAuthorized) {
    return (
      <Container fluid>
        <Row>
          <Col className="text-center">
            <h1>Not Authorized</h1>
            <p>You do not have permission to view this page.</p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Row>
        {devices && devices.length > 0 ? (
          <Col xxs="12" className="mb-4 mt-4">
            <Card className="mb-4 shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between">
                  <h2 className="mb-0">Devices</h2>
                </div>
              </CardHeader>
              <DevicesTable devices={devices} handleRefresh={handleRefresh} />
            </Card>
          </Col>
        ) : (
          <div
            className="flex-column d-flex w-100 justify-content-center align-item-center"
            style={{height: '80vh'}}
          >
            <div className="flex-row d-flex justify-content-center align-item-center">
              <div
                className={
                  'flex-column d-flex justify-content-center align-item-center ' +
                  (isSmall ? 'pl-3 pr-3' : null)
                }
              >
                <p
                  className="align-item-center justify-content-center d-flex text-lmedium  font-weight-semibold"
                  style={{textAlign: 'center'}}
                >
                  Deocamdata nu ai nici un device adăugat.
                </p>
              </div>
            </div>
          </div>
        )}
      </Row>
    </Container>
  );
};
export default Devices;
